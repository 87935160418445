import styles from "pages/user/UserBasket.module.scss"
import IconDelete from "icons/icon-delete.svg"
import IconLike from "icons/icon-like.svg"
import IconLikeFilled from 'icons/icon-like-filled.svg'
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { basketDeleteItem, basketGet, basketUpdateCount, IBasket } from "server-api/basket";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { viewAlert } from "redux-store/notifications";
import { IProduct, productGetById } from "server-api/product";
import { addToFavourite, checkProductInFavourites, deleteFavourite } from "server-api/favourite";
import Loader from "components/Loader";
import { adminDataGet } from "server-api/admin-data";

const BasketItem = ({ data, updateComponent, currentRate }: { data: IBasket, updateComponent: () => void, currentRate: number }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [isFavourite, setIsFavourite] = useState<boolean>(false);
  const [productData, setProductData] = useState<IProduct>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await adminDataGet()
      .catch((error) => console.log("Error while get admin data: " + error));
    }) ();
  }, []);
  
  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await productGetById(String(data.product_id))
      .then((resp) => {
        setProductData(resp.data as IProduct);
      })
      .catch((error) => {
        dispatch(viewAlert({text: "Продукт с таким id не найден", type: "error"}));
      });

      setIsLoading(false);
    }) ()
  }, [data.product_id]);

  useEffect(() => {
    (async () => {
      await checkProductInFavourites(data.product_id as number)
      .then((resp) => resp.data.response === true ? setIsFavourite(true) : setIsFavourite(false))
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
    }) ()
  }, [isFavourite]);

  const handleAddToFavourite = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isFavourite) {
      await deleteFavourite(data.product_id as number)
      .then((resp) => setIsFavourite(false))
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
    } else {
      await addToFavourite(data.product_id as number)
      .then((resp) => setIsFavourite(true))
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
    }
  }

  const handleSetCount = async (num: number) => {
    if (num === -1) {
      if (data.count !== 1) {
        await basketUpdateCount({id: data.id as number, operation: "minus"})
        .then((resp) => updateComponent())
        .catch((error) => {
          dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
          navigate("/authorization?next=" + location.pathname + location.search);
        });
      }
    } else {
      await basketUpdateCount({id: data.id as number, operation: "plus"})
      .then((resp) => updateComponent())
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
    }
  }

  const handleDeleteItem = async (id: number) => {
    await basketDeleteItem(id)
      .then((resp) => updateComponent())
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
  }

  return (
    <div className={styles.basket_item}>
      {
        isLoading ? <Loader/>
        :
          <>
            <div className={styles.img_container}>
              <img src={productData?.images.Images[0]} alt="product image" />
            </div>
            
            <div className={styles.basket_descriptions}>
              <Link to={"/product?id=" + data.product_id}>
                <h1>{productData?.title}</h1>
              </Link>
              <p>
                Артикул: {productData?.article}<br></br>
                {
                  data.color === "" ? <>Размер: <b>{data.size}</b> </> : <>Цвет: <b>{data.color}</b>, размер: <b>{data.size}</b></>
                }
              </p>
              <h2>{data.price * data.count * currentRate} ₽</h2>

              <div className={styles.actions_container}>
                <button className={styles.action_button} onClick={(e) => handleAddToFavourite(e)}>
                  <img 
                    src={isFavourite ? IconLikeFilled : IconLike} 
                    alt="favourite"
                  />
                </button>

                <button className={styles.action_button} onClick={() => handleDeleteItem(data.id as number)}>
                  <img src={IconDelete} alt="delete"/>
                </button>

                <div className={styles.item_counter}>
                  <button className={data.count === 1 ? styles.unactive_button : styles.action_button} onClick={() => handleSetCount(-1)}>-</button>
                  <p>{data.count}</p>
                  <button className={styles.action_button} onClick={() => handleSetCount(1)}>+</button>
                </div>
              </div>
            </div>
          </>
      }
      
    </div>
  )
}

const UserBasket = () => {
  const [items, setItems] = useState<IBasket[]>([]);
  const [allPrice, setAllPrice] = useState<number>(0);
  const [isUpdate, updateComponent] = useState<boolean>(false);
  const [isFastDelivery, setIsFastDelivery] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentRate = useAppSelector((state) => state.adminData.current_rate);

  const deliveryRef = React.createRef<HTMLSelectElement>();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await basketGet()
      .then((resp) => {
        setItems(resp.data);
        setAllPrice((resp.data as IBasket[]).reduce((total, item) => total += item.price * item.count, 0));
      })
      .catch((error) => {
        dispatch(viewAlert({text: "Ошибка: " + error, type: "error"}));
      });

      setIsLoading(false);
    }) ()
  }, [isUpdate]);

  const handleOnChangeDelivery = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "default") {
      setIsFastDelivery(false);
    } else {
      setIsFastDelivery(true);
    }
  }

  return (
    <div className={styles.container}>
      {
        isLoading ?
          <Loader />
        :
          items?.length === 0 ? <p>Корзина пустая</p>
          :
          <>
            <div className={styles.basket_items_container}>
              {
                items?.map((item) => <BasketItem key={item.id} data={item} currentRate={currentRate} updateComponent={() => updateComponent(prev => !prev)}/>)
              }
            </div>

            <div className={styles.right_block}>
              <select ref={deliveryRef} aria-label="Выберите тип доставки" onChange={(e) => handleOnChangeDelivery(e)}>
                <option value="default">Обычная доставка (бесплатно)</option>
                <option value="fast">Быстрая доставка (2500 ₽)</option>
              </select>

              <div className={styles.price_block}>
                <h1>Итого:</h1>
                <h1>{allPrice * currentRate + (isFastDelivery ? 2500 : 0)} ₽</h1>
              </div>
              <button 
                onClick={() => navigate("/create-order?fast=" + (deliveryRef.current?.value === "default" ? "false" : "true"))}
              >
                Оформить заказ
              </button>
            </div>
          </>
      }
    </div>
  );
}

export default UserBasket;