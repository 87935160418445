import styles from 'components/Header.module.scss'
import "components/modals/ModalsGlobalStyles.css"
import Logo from 'images/logo.svg'
import IconSearch from 'icons/icon-search.svg'
import IconUser from 'icons/icon-user.svg'
import IconLike from 'icons/icon-like.svg'
import IconBasket from 'icons/icon-basket.svg'
import IconMenu from 'icons/icon-menu.svg'
import { createSearchParams, Link, useNavigate } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import { useRef, useState } from 'react'
import CategoriesModal from './modals/CategoriesModal'
import HandleResponsiveView, { screenSizes } from 'utils/ResponsiveView'
import MobileMenu from './modals/MobileMenu'
import { useAppSelector } from 'redux-store/hooks'

export interface IClothes {
  name: string;
  isBold: boolean;
  link: string
};

// mcat - main category, scat - category, cat = subcategory

const CategoriesClothes: IClothes[] = [
  {name: "Все", isBold: true, link: "/products?mcat=/Apparel"}, //
  {name: "Футболки", isBold: false, link: "/products?cat=/T-shirt"},
  {name: "Рубашки", isBold: false, link: "/products?cat=/Shirt"}, 
  {name: "Футболки Поло", isBold: false, link: "/products?cat=/Polo_Shirt"},
  {name: "Майки", isBold: false, link: "/products?cat=singlet"},
  {name: "Худи и толстовки", isBold: false, link: "/products?scat=/HoodiesSweatshirts"}, //
  {name: "Свитеры", isBold: false, link: "/products?scat=/Sweaters"}, //
  {name: "Шорты и юбки", isBold: false, link: "/products?scat=/ShortsSkirts"}, //
  {name: "Верхняя одежда", isBold: true, link: "/products?scat=/JacketsCoats"},
  {name: "Пиджаки", isBold: false, link: "/products?cat=/Jacket"},
  {name: "Летние", isBold: false, link: "/products?cat=summer"},
  {name: "Пальто", isBold: false, link: "/products?scat="},
  {name: "Безрукавки", isBold: false, link: "/products?cat=sleeveless-jacket"},
  {name: "Кожаные куртки", isBold: false, link: "/products?cat=/Leather_Jacket"},
  {name: "Спортивные куртки", isBold: false, link: "/products?cat=sport-jackets"},
  {name: "Пуховики", isBold: false, link: "/products?cat=/Down_Jacket"},
  {name: "Брюки", isBold: false, link: "/products?scat=/Pants"}, //
  {name: "Спортивные костюмы", isBold: false, link: "/products?scat=/Activewear"},
  {name: "Нижнее белье", isBold: false, link: "/products?cat=lingerie"},
  {name: "Носки", isBold: false, link: "/products?cat=/Sock"},
  {name: "Платья", isBold: false, link: "/products?cat=dresses"},
  {name: "Фитнес-Одежда", isBold: false, link: "/products?cat=fitness-clothes"},
  {name: "Подтяжки", isBold: false, link: "/products?cat=suspendors"},
  {name: "Детская", isBold: true, link: "/products?scat=children-clothes"}
];

const CategoriesShoes: IClothes[] = [
  {name: "Кроссовки", isBold: true, link: "/products?mcat=/Sneakers"}, //
  {name: "Баскетбольные", isBold: false, link: "/products?scat=/Basketball"}, //
  {name: "Для скейтбординга", isBold: false, link: "/products?scat=/Skateboarding"}, // 
  {name: "Для бега", isBold: false, link: "/products?scat=/Running"}, //
  {name: "Для тренировок", isBold: false, link: "/products?scat=/Training"}, //
  {name: "Бутсы", isBold: false, link: "/products?scat="}, 
  {name: "Уличные", isBold: false, link: "/products?scat=/Outdoors"},
  {name: "Повседневная обувь", isBold: true, link: "/products?scat=/Casual_Shoes"},
  {name: "Лоферы", isBold: false, link: "/products?cat=loafers"},
  {name: "Эспадрильи", isBold: false, link: "/products?cat=espadrilles"},
  {name: "Мокасины", isBold: false, link: "/products?cat=moccasins"},
  {name: "Mary Jane", isBold: false, link: "/products?cat=mary-jane"},
  {name: "Женские туфли", isBold: false, link: "/products?cat=woomen-shoes"},
  {name: "Мужские туфли", isBold: false, link: "/products?cat=men-shoes"},
  {name: "Кожаная обувь", isBold: false, link: "/products?cat=leather-shoes"},
  {name: "Тапки", isBold: false, link: "/products?cat=slippers"},
  {name: "Сандалии", isBold: false, link: "/products?scat=/Sandals_FlipFlops"},
  {name: "Клоги", isBold: false, link: "/products?cat=clogs"},
  {name: "Ботинки", isBold: true, link: "/products?mcat=/Shoes"},
  {name: "Мартинсы", isBold: false, link: "/products?cat=martins"},
  {name: "Уличные", isBold: false, link: "/products?scat=/Outdoors"},
  {name: "Зимние", isBold: false, link: "/products?cat=winter-shoes"},
  {name: "Челси", isBold: false, link: "/products?cat=chelsea"},
  {name: "Сапоги", isBold: false, link: "/products?scat=/Boots"}, //
  {name: "Детские", isBold: true, link: "/products?scat=children-shoes"}
];

const CategoriesAccessories: IClothes[] = [
  {name: "Все", isBold: true, link: "/products?mcat=/Accessories"},
  {name: "Головные уборы", isBold: true, link: "/products?scat=/Hats_Caps"},
  {name: "Кепки", isBold: false, link: "/products?cat=caps"},
  {name: "Панамы", isBold: false, link: "/products?cat=panama"},
  {name: "Шапки", isBold: false, link: "/products?cat=headings"},
  {name: "Другие шапки", isBold: false, link: "/products?cat=other-headings"},
  {name: "Береты", isBold: false, link: "/products?cat=berets"},
  {name: "Рюкзаки и сумки", isBold: true, link: "/products?mcat=/Bags"},
  {name: "Рюкзаки", isBold: false, link: "/products?scat=/Backpacks"},
  {name: "Сумки через плечо", isBold: false, link: "/products?scat=/Shoulder_Bags"},
  {name: "Поясные сумки", isBold: false, link: "/products?scat=/BumBags_BeltBags"},
  {name: "Портфели", isBold: false, link: "/products?cat=briefcases"},
  {name: "Спортивные сумки", isBold: false, link: "/products?cat=sport-bags"},
  {name: "Нагрудные сумки", isBold: false, link: "/products?cat=chest-bags"},
  {name: "Сумки", isBold: false, link: "/products?scat=/Bags"},
  {name: "Сумочки", isBold: false, link: "/products?scat=/Handbags"},
  {name: "Клатчи", isBold: false, link: "/products?cat=clutches"},
  {name: "Косметички", isBold: false, link: "/products?cat=cosmetic-bags"},
  {name: "Для наушников", isBold: false, link: "/products?cat=headphones-accessories"},
  {name: "Для украшений", isBold: false, link: "/products?cat=jewelry-accessories"},
  {name: "Для телефонов", isBold: false, link: "/products?cat=phones-accessories"},
  {name: "Для хранения", isBold: false, link: "/products?cat=storage-accessories"},
  {name: "Кейсы", isBold: false, link: "/products?cat=cases"},
  {name: "Паспортницы", isBold: false, link: "/products?cat=passport-holders"},
  {name: "Кошельки", isBold: false, link: "/products?cat=wallets"},
  {name: "Кардхолдеры", isBold: false, link: "/products?cat=cardholders"},
  {name: "Ключницы", isBold: false, link: "/products?cat=housekeepers"},
  {name: "Детские рюкзаки", isBold: false, link: "/products?cat=children-backpacks"},
  {name: "Часы", isBold: true, link: "/products?scat=/Watches"},
  {name: "Умные", isBold: false, link: "/products?cat=smart-watches"},
  {name: "Механические", isBold: false, link: "/products?cat=mechanical-watches"},
  {name: "Кварцевые", isBold: false, link: "/products?cat=quartz-watches"},
  {name: "Будильники", isBold: false, link: "/products?cat=alarm-clocks"}
];

export default function Header() {
  const [viewCategories, setViewCategories] = useState({clothes: false, shoes: false, accessories: false});
  const [viewMobileMenu, setViewMobileMenu] = useState<boolean>(false);

  const userData = useAppSelector(state => state.auth.userData);
  const searchRef = useRef<HTMLInputElement>(null);
  const screenSize = HandleResponsiveView();
  const navigate = useNavigate();

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      navigate({pathname: "/products", search: createSearchParams({s: e.target.value}).toString()});
      if (searchRef.current) searchRef.current.value = "";
      if (searchRef.current) searchRef.current.blur();
    }
  }  

  return (
    <header>
      <div className={styles.container}>

        {
          screenSize.width <= screenSizes.__480.width && 
            <img 
              className={styles.menu_button} 
              src={IconMenu} 
              onClick={() => setViewMobileMenu(true)}
              alt='user' 
            />
        }

        <Link to="/">
          <img className={styles.logo} src={Logo} alt='swup store' />
        </Link>

        <div className={styles.menu}>
          <button onClick={() => setViewCategories({...viewCategories, clothes: true})}>Одежда</button>
          <button onClick={() => setViewCategories({...viewCategories, shoes: true})}>Обувь</button>
          <button onClick={() => setViewCategories({...viewCategories, accessories: true})}>Аксессуары</button>
        </div>

        <div className={styles.right_menu}>
          <div className={styles.search}>
            <input 
              type='search' 
              defaultValue="" 
              placeholder='Найти' 
              onKeyUp={handleKeyUp} 
              ref={searchRef} 
            />
            <img src={IconSearch} alt='search' />
          </div>

          <Link to="/profile">
            <img src={IconUser} alt='user' />
          </Link>
          <Link to="/favourites">
            {
              userData.favourites !== 0 ? 
                <div className={styles.icon_badge}>
                  <div className={styles.circle}>
                    <p>{userData.favourites}</p>
                  </div>
                  <img src={IconLike} alt='favourites' />
                </div>
              : 
                <img src={IconLike} alt='favourites' />
            }
          </Link>
          <Link to="/basket">
            {
                userData.basket !== 0 ? 
                  <div className={styles.icon_badge}>
                    <div className={styles.circle}>
                      <p>{userData.basket}</p>
                    </div>
                    <img src={IconBasket} alt='basket' />
                  </div>
                : 
                  <img src={IconBasket} alt='basket' />
              }
          </Link>
        </div>
      </div>

      <Modal 
        open={viewCategories.clothes} 
        onClose={() => setViewCategories({...viewCategories, clothes: false})} 
        showCloseIcon={false} 
        classNames={{modal: "categories_container"}}
      >
        <CategoriesModal data={CategoriesClothes} closeModal={() => setViewCategories({...viewCategories, clothes: false})} itemsPerRow={8}/>
      </Modal>

      <Modal 
        open={viewCategories.shoes} 
        onClose={() => setViewCategories({...viewCategories, shoes: false})} 
        showCloseIcon={false} 
        classNames={{modal: "categories_container"}}
      >
        <CategoriesModal data={CategoriesShoes} closeModal={() => setViewCategories({...viewCategories, shoes: false})} itemsPerRow={7}/>
      </Modal>

      <Modal 
        open={viewCategories.accessories} 
        onClose={() => setViewCategories({...viewCategories, accessories: false})} 
        showCloseIcon={false} 
        classNames={{modal: "categories_container"}}
      >
        <CategoriesModal data={CategoriesAccessories} closeModal={() => setViewCategories({...viewCategories, accessories: false})} itemsPerRow={7}/>
      </Modal>

      <Modal 
        open={viewMobileMenu} 
        onClose={() => setViewMobileMenu(false)} 
        showCloseIcon={false} 
        classNames={{
          modal: "mobile_menu_container",
          modalAnimationIn: 'customEnterModalAnimation',
          modalAnimationOut: 'customLeaveModalAnimation'
        }}
        animationDuration={400}
        blockScroll={false}
      >
        <MobileMenu 
          closeModal={() => setViewMobileMenu(false)}
          CategoriesClothes={CategoriesClothes}
          CategoriesAccessories={CategoriesAccessories}
          CategoriesShoes={CategoriesShoes}
        />
      </Modal>
    </header>
  )
}