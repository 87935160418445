import styles from 'pages/about/RequisitesPage.module.scss'

export default function RequisitesPage() {
  return (
    <section>
      <div className={styles.container}>
        <h1>Реквизиты</h1>
        <p>
          Номер телефона: <b>+7 981 918 2246</b><br/>
          Почта: <b>swup.store@gmail.com</b><br/>
          ИП: <b>Кирунец А.С.</b><br/>
          ИНН: <b>110406279426</b><br/>
          ОГРН: <b>323784700225840</b><br/>
        </p>
      </div>
    </section>
  )
}