import Card from "components/Card";
import styles from "pages/user/UserFavourite.module.scss"
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store/hooks";
import { getFavourites } from "server-api/favourite";
import { viewAlert } from "redux-store/notifications";
import { IProduct } from "server-api/product";
import Loader from "components/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const UserFavourite = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [favourites, setFavourites] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getFavourites()
      .then((resp) => setFavourites(resp.data as IProduct[]))
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });

      setIsLoading(false);
    }) ()
  }, []);

  return (
    <div className={styles.container}>
      {
        isLoading ? <Loader />
        :
        favourites.length === 0 ? <p>У вас нет избранных товаров</p>
        :
          <>
            {
              favourites.map((item) => 
                <Card 
                  key={item.id}
                  id={item.id}
                  title={item.title}
                  brand={item.brand}
                  main_category_id={item.main_category_id}
                  category_id={item.category_id}
                  sub_category_id={item.sub_category_id}
                  article={item.article}
                  images={item.images}
                  data={item.data}
                  price={item.price}
                />
              )
            }
          </>
      }
    </div>
  );
}

export default UserFavourite;