import styles from "components/modals/ChangePasswordModal.module.scss"
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store/hooks";
import { viewAlert } from "redux-store/notifications";
import { userUpdatePassword } from "server-api/users";

const ChangePasswordModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElements = e.currentTarget.elements as typeof e.currentTarget.elements & {
      old_pswd: HTMLInputElement,
      new_pswd1: HTMLInputElement,
      new_pswd2: HTMLInputElement
    };

    if (formElements.new_pswd1.value !== formElements.new_pswd2.value) {
      dispatch(viewAlert({text: "Новый пароль должен совпадать", type: "warning"}));
      return;
    }
    await userUpdatePassword({old_password: formElements.old_pswd.value, new_password: formElements.new_pswd1.value})
      .then((resp) => {
        dispatch(viewAlert({text: "Пароль успешно обновлен", type: "success"}));
        onClose();
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      })
  }

  return (
    <div className={styles.container}>
      <h1>Сменить пароль</h1>

      <form onSubmit={handleOnSubmit}>
        <input
          name="old_pswd"
          type="password" 
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
          title="Пароль должен быть 8+ символов и должен содержать минимум одну заглавную букву, одну обычную и одну цифру"
          required 
          minLength={8} 
          maxLength={30} 
          placeholder="Старый пароль"
        />

        <input
          name="new_pswd1"
          type="password" 
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
          title="Пароль должен быть 8+ символов и должен содержать минимум одну заглавную букву, одну обычную и одну цифру"
          required 
          minLength={8} 
          maxLength={30} 
          placeholder="Новый пароль"
        />

        <input
          name="new_pswd2"
          type="password" 
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
          title="Пароль должен быть 8+ символов и должен содержать минимум одну заглавную букву, одну обычную и одну цифру"
          minLength={8} 
          placeholder="Повторите новый пароль"
        />

        <button type="submit">Сменить</button>
      </form>
    </div>
  );
}

export default ChangePasswordModal;
