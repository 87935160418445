import styles from 'pages/faq/PaymentMethods.module.scss'

export default function AboutCompanyPage() {
  return (
    <section>
      <div className={styles.container}>
        <h1>О нас</h1>
        <p>
          <b>SWUP</b> – это маркетплейс, на котором собрано более 350 брендов
          одежды, обуви и аксессуаров. Здесь ты сможешь найти все
          популярные, а также ушедшие из России бренды по самой низкой и
          честной цене 
        </p>
      </div>
    </section>
  )
}