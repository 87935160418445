import styles from 'components/Card.module.scss'
import IconLike from 'icons/icon-like.svg'
import IconLikeFilled from 'icons/icon-like-filled.svg'
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux-store/hooks';
import { viewAlert } from 'redux-store/notifications';
import { addToFavourite, checkProductInFavourites, deleteFavourite } from 'server-api/favourite';
import { IProduct } from 'server-api/product'
import { jwtDecode } from 'jwt-decode';
import { IToken, setStateLogout } from 'redux-store/user-auth';
import { userUpdateToken } from 'server-api/users';

export default function Card(data: IProduct) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const authState = useAppSelector((state) => state.auth);
  const currentRate = useAppSelector((state) => state.adminData.current_rate);
  const [isFavourite, setIsFavourite] = useState<boolean>(false);

  useEffect(() => {
    if (authState.userMail !== null) {
      (async () => {
        await checkProductInFavourites(data.id)
        .then((resp) => resp.data.response === true ? setIsFavourite(true) : setIsFavourite(false))
        .catch((error) => {});
      }) ()
    }
  }, [isFavourite]);

  const handleOnClick = async (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isFavourite) {
      await deleteFavourite(data.id)
      .then((resp) => setIsFavourite(false))
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
    } else {
      await addToFavourite(data.id)
      .then((resp) => setIsFavourite(true))
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
    }
  }

  return (
    <Link to={"/product?id=" + data.id}>
      <div className={styles.container}>
        <div className={styles.image_block}>
          <img src={data.images.Images[0]} alt='image' />

          <img 
            className={styles.like_button} 
            src={isFavourite ? IconLikeFilled : IconLike} 
            onClick={(e) => handleOnClick(e)}
            alt='like' 
          />
        </div>
        <h2>{data.price * currentRate} ₽</h2>
        <p>{data.title}</p>
      </div>
    </Link>
  )
}