import Loader from "components/Loader";
import styles from "pages/PaymentPage.module.scss"
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getOrder } from "server-api/orders";

const PaymentPage = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState<"pending" | "waiting_for_capture" | "succeeded" | "canceled">("pending");
  const navigate = useNavigate();
  const order_id = searchParams.get("order");

  useEffect(() => {
    if (!order_id) {
      navigate("/");
    } else {
      (async () => {
        await getOrder(order_id)
        .then((resp) => {setPaymentStatus(resp.data.status); console.log(resp.data)})
        .catch((error) => {});

        setIsLoading(false);
      }) ()
    }
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content_block}> 
        {
          isLoading ? <Loader /> : paymentStatus === "pending" ? 
            <>
              <h2>Ожидается оплата заказа...</h2>
              <p>Если вы уже оплатили заказ - обновите эту страницу</p>
              <button
                onClick={() => window.location.reload()}
              >
                Обновить страницу
              </button>
            </>
          : paymentStatus === "waiting_for_capture" ?
            <>
              <h2>Платеж обрабатывается...</h2>
              <p>Пожалуйста, подождите</p>
            </>
          : paymentStatus === "succeeded" ?
            <>
              <h2>Заказ успешно оплачен</h2>
              <p>Вы можете посмотреть его состав и статус в личном кабинете<br></br>Ждем вас снова!</p>
              <button
                onClick={() => navigate("/orders")}
              >
                Перейти к заказам
              </button>
            </>
          : 
            <>
              <h2>Во время оплаты произошла ошибка:(</h2>
              <p>Пожалуйста, попробуйте еще раз оформить и оплатить заказ</p>
              <button
                onClick={() => navigate("/basket")}
              >
                Перейти в корзину
              </button>
            </>
        }
      </div>
    </div>
  );
}

export default PaymentPage;
