import axios from "axios";
import store from "redux-store/store";
import { SERVER_ADDRESS, SERVER_API_VERSION } from "./config";

export interface ISize {
  [key: string]: number;
}

export interface IColorSize {
  [color: string]: ISize[];
}

export interface IProduct {
  id: number;
  main_category_id: number;
  category_id: number | null;
  sub_category_id: number;
  brand: string;
  title: string;
  article: string;
  images: { Images: string[] };
  data: { Sizes: ISize[] } | IColorSize
  price: number;
  created_at?: string;
}

interface IProductsFilter {
  filterBy: "main_category_link" | "category_link" | "subcategory_link";
  value: string;
  count?: number;
}

interface IProductsSimilar {
  filterBy: "subcategory_id" | "brand_title";
  value: string;
  product_id: number;
  count?: number;
}


export async function productsGetPopular(count: number) {
  try {
    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}products/popular/`, 
    { 
      headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` },
      params: { count: count }
    }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function productGetById(product_id: string) {
  try {
    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}products/get/`, 
    { 
      headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` },
      params: { product_id: product_id }
    }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function productsFilter(data: IProductsFilter) {
  try {
    const params: { [key: string]: any } = {
      [data.filterBy]: data.value
    };

    if (data.count !== undefined) {
      params.count = data.count;
    }

    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}products/filter/`, 
    { 
      headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` },
      params: params
    }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function productsSimilar(data: IProductsSimilar) {
  try {
    const params: { [key: string]: any } = {
      [data.filterBy]: data.value,
      "product_id": data.product_id
    };

    if (data.count !== undefined) {
      params.count = data.count;
    }

    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}products/similar/`, 
    { 
      headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` },
      params: params
    }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function productsSearch(query: string, count: number) {
  try {
    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}products/search/`, 
    { 
      headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` },
      params: {query: query, count: count}
    }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}