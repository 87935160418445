import styles from 'App.module.scss';
import 'react-responsive-modal/styles.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import MainPage from 'pages/MainPage';
import ErrorPage from 'pages/ErrorPage';
import Header from 'components/Header';
import Footer from 'components/Footer';
import HowToOrderPage from 'pages/faq/HowToOrderPage';
import HowToRegPage from 'pages/faq/HowToRegPage';
import ScrollToTop from 'utils/ScrollToTop';
import PaymentMethods from 'pages/faq/PaymentMethodsPage';
import DeliveryPage from 'pages/faq/DeliveryPage';
import AboutCompanyPage from 'pages/about/AboutCompanyPage';
import RequisitesPage from 'pages/about/RequisitesPage';
import FaqPage from 'pages/about/FaqPage';
import UserPage from 'pages/user/UserPage';
import ProductView from 'pages/ProductView';
import ProductsPage from 'pages/ProductsPage';
import { useAppDispatch, useAppSelector } from 'redux-store/hooks';
import AuthPage from 'pages/auth/AuthPage';
import RegisterPage from 'pages/auth/RegisterPage';
import RecoverPasswordPage from 'pages/auth/RecoverPassword';
import ProtectedPage from 'pages/ProtectedPage';
import Alert from 'components/Alert';
import { useEffect, useRef } from 'react';
import { hideAlert } from 'redux-store/notifications';
import { adminDataGet } from 'server-api/admin-data';
import { setUserData } from 'redux-store/user-auth';
import PaymentPage from 'pages/PaymentPage';
import validateTokens from 'server-api/auth';
import { getFavourites } from 'server-api/favourite';
import { IProduct } from 'server-api/product';
import { basketGet, IBasket } from 'server-api/basket';
import ActivateUserPage from 'pages/auth/ActivateUserPage';

const App = () => {
  const alertState = useAppSelector((state) => state.notification);
  const authState = useAppSelector((state) => state.auth);
  const timerId = useRef<NodeJS.Timeout>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    timerId.current = setTimeout(() => {
      dispatch(hideAlert());
    }, 3000);
    
    return () => clearTimeout(timerId.current);
  }, [alertState.isShow]);

  useEffect(() => {
    (async () => {
      await validateTokens()
      .catch((err) => {});
    }) ();
  }, []);

  useEffect(() => {
    (async () => {
      await adminDataGet()
      .catch((error) => console.log("Error while get admin data: " + error));
    }) ();
  }, []);

  useEffect(() => {
    (async () => {
      await getFavourites()
      .then((resp) => {
        dispatch(setUserData({favourites: (resp.data as IProduct[]).length}))
      })
      .catch((error) => {});
    }) ();
  }, [authState.userMail]);

  useEffect(() => {
    (async () => {
      await basketGet()
      .then((resp) => {
        dispatch(setUserData({basket: (resp.data as IBasket[]).length}))
      })
      .catch((error) => {});
    }) ();
  }, [authState.userMail]);

  return (
    <HashRouter>
      <div className={styles.app}>
        <Header />

        {
          alertState.isShow ? <Alert text={alertState.text} type={alertState.type} /> : <></>
        }
        
        <ScrollToTop />
        <Routes>
          <Route path="/how-to-order" element={<HowToOrderPage />} />
          <Route path="/how-to-reg" element={<HowToRegPage />} />
          <Route path="/payment-methods" element={<PaymentMethods />} />
          <Route path="/delivery" element={<DeliveryPage />} />
          <Route path="/about" element={<AboutCompanyPage />} />
          <Route path="/requisites" element={<RequisitesPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/activate" element={<ActivateUserPage />} />
          <Route path="/profile" element={<ProtectedPage page={<UserPage page="profile"/>}/>}/>
          <Route path="/addresses" element={<ProtectedPage page={<UserPage page="addresses"/>}/>} />
          <Route path="/orders" element={<ProtectedPage page={<UserPage page="orders"/>}/>} />
          <Route path="/favourites" element={<ProtectedPage page={<UserPage page="favourites"/>}/>} />
          <Route path="/basket" element={<ProtectedPage page={<UserPage page="basket"/>}/>} />
          <Route path="/create-order" element={<ProtectedPage page={<UserPage page="create-order"/>}/>} />
          <Route path="/product" element={<ProductView />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/authorization" element={<AuthPage />} />
          <Route path="/registration" element={<RegisterPage />} />
          <Route path="/recover-password" element={<RecoverPasswordPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/" element={<MainPage />}/>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </div>
    </HashRouter>
  );
}

export default App;
