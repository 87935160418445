import styles from 'pages/faq/HowToOrderPage.module.scss'

export default function DeliveryPage() {
  return (
    <section>
      <div className={styles.container}>
        <h1>Доставка и возврат</h1>
        <p>
          Мы предоставляем доставку товаров по территории Российской Федерации, через курьерскую службу СДЭК. После оформления заказа и подтверждения оплаты, обеспечивается отправка вашего товара из Китая. Мы следим за логистикой товара, чтобы гарантировать своевременную доставку вашего заказа из Китая и последующую доставку по России до вашего адреса. ***
          <br/><br/>
          1. Территория доставки: Мы осуществляем доставку товаров по всей территории Российской Федерации. <br></br>
          2. Логистика из Китая: Мы принимаем на себя логистику товаров, обеспечивая своевременную отгрузку вашего заказа из Китая.<br></br>
          3. Обработка заказа: После подтверждения оплаты, мы оперативно обрабатываем ваш заказ и организуем отправку товара.<br></br>
          4. Доставка через СДЭК: Сотрудничаем с курьерской службой СДЭК для обеспечения надежной и оперативной доставки вашего заказа.<br></br>
          5. Отслеживание посылки: Вы получаете возможность отслеживать вашу посылку по номеру для уверенности в своевременной доставке.<br></br>
          6. Гарантированные сроки: Мы гарантируем, что ваш заказ будет отправлен в срок, обеспечивая удовлетворение клиентов сервисом.<br></br>
          7. Поддержка клиентов: Наша команда оперативно реагирует на ваши вопросы и обеспечивает поддержку в процессе доставки.<br></br>
          8. Надежная упаковка: Мы заботимся о том, чтобы ваш заказ был хорошо упакован для защиты от повреждений во время транспортировки.<br></br>
          9. Разумные расценки: Мы предлагаем разумные тарифы на доставку, обеспечивая оптимальное соотношение цены и качества. <br></br>
        </p>
        <h1>ВОЗВРАТ ТОВАРА</h1>
        <p>
          Мы предоставляем доставку товаров по территории Российской Федерации, через курьерскую службу СДЭК. После оформления заказа и подтверждения оплаты, обеспечивается отправка вашего товара из Китая. Мы следим за логистикой товара, чтобы гарантировать своевременную доставку вашего заказа из Китая и последующую доставку по России до вашего адреса. Мы понимаем важность удовлетворения ваших потребностей, поэтому предлагаем возможность возврата товара при определенных условиях. Обратите внимание, что как российская компания, работающая с китайскими поставщиками, мы не являемся владельцами интернет-магазина, а лишь обеспечиваем процесс заказа, логистику и доставку товаров. Если у вас возникли вопросы о возврате товара, пожалуйста, свяжитесь с нашим отделом обслуживания клиентов для получения дополнительной информации по процедуре возврата.<br/> 
        </p>
        <p>
          <b>1. Процедура возврата</b><br/>
          1.1. Клиент не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его Клиентом.<br/>
          1.2. При отказе Клиента от товара Сайт возвращает ему денежную сумму, уплаченную за Товар, за вычетом суммы расходов Сайта, связанных с доставкой товара Клиенту и вывозом товара от Клиента, не позднее, чем через десять дней со дня предъявления Оператором соответствующего требования.<br/>
          1.3. Товар ненадлежащего качества может быть возвращен Оператору, в данном случае доставку товара оплачивает Оператор.<br/>
          1.4. На Сайте может быть предоставлен ряд Товаров, которые не подлежат обмену или возврату по закону.<br/>
          1.5. Оператор не может нести риски за Клиентов, которые по каким-то своим личным причинам захотят вернуть Товар. Причиной возврата может быть только брак!<br/>
          Поэтому при приобретении Товаров, просьба производить осмотр Товара в присутствии курьеров или специалистов нашей компании. В случае доставки курьерскими компаниями в другие регионы, просьба производить осмотр при получении Товара в курьерской службе. В противном случае Оператор не сможет произвести возврат суммы за бракованный Товар.<br/>
          <b>2. Обратная связь</b><br/> Наша команда готова оказать помощь и рассмотреть запросы на возврат товара в соответствии с действующими правилами.<br/>
          <b>3. Сроки и условия</b><br/> Убедитесь в соответствии ваших запросов условиям возврата на нашем веб-сайте.<br/>
          <b>4. Контактная информация</b><br/> Если у вас возникли вопросы о возврате товара, свяжитесь с нашим отделом обслуживания клиентов для получения непосредственной поддержки.<br/>
          <b>5. Товары в первоначальном виде, с сохранением товарного вида</b><br/> Мы принимаем возврат товаров только в состоянии, не измененном с момента покупки.<br/>
          <b>6. Предостережение перед покупкой</b><br/> Мы рекомендуем всем Клиентам внимательно изучить условия возврата до совершения покупки.<br/>
          <b>7. Запрос на возврат</b><br/> В случае возникновения необходимости возврата товара, свяжитесь с нами для запроса возврата и получение инструкций о дальнейших действиях.<br/>
          <b>8. Компенсация стоимости</b><br/> В случае согласования возврата, мы гарантируем возврат средств в соответствии с условиями нашей политики возврата товара.<br/>
          <b>9. Индивидуальный подход</b><br/> Мы стараемся удовлетворить потребности каждого Клиента и предоставить индивидуальные решения в рамках внутренних процедур компании. <br/>
        </p>
        <h1>УСЛОВИЯ ВОЗВРАТА ТОВАРА</h1>
        <p>
          <b>Какие Товары не подлежат возврату?</b>
          <br/><br/>
          <b>- Обмен и возврат Товаров парфюмерно-косметических товаров</b><br/> Уважаемые Клиенты! Парфюмерно-косметические товары надлежащего качества с действующим сроком годности обмену и возврату не подлежат. Мы готовы принять ваш Товар к возврату в течение 14 календарных дней с момента отправки только в том случае, если вы получили Товар с истекшим сроком годности. Качество Товара вы должны проверить при получении. Мы не продаем Товар с истекшим сроком годности, но не исключается вероятность того, что случайно мог быть отгружен Товар из старой партии.
          <br/><br/>
          Во всех указанных ниже случаях мы не принимаем парфюмерно-косметические товары к возврату, в том числе, если:<br/>
          - вы ошиблись артикулом<br/>
          - вы ошиблись с количеством товара<br/>
          - вам больше не нужен данный товар<br/>
          <br/>
          <b>ПОСТАНОВЛЕНИЕ от 19 января 1998 г. № 55</b><br/>
          Перечень непродовольственных товаров надлежащего качества, не подлежащих возврату или обмену на аналогичный товар других размера, формы, габарита, фасона, расцветки или комплектации<br/>
          1. Парфюмерно-косметические товары<br/>
          2. Товары для профилактики и лечения заболеваний в домашних условиях (предметы санитарии и гигиены из металла, резины, текстиля и других материалов, инструменты, приборы и аппаратура медицинские, средства гигиены полости рта, линзы очковые, предметы по уходу за детьми)<br/>
          3. Предметы личной гигиены (зубные щетки, расчески, заколки, бигуди для волос, парики, шиньоны и другие аналогичные товары)<br/>
          4. Текстильные товары (хлопчатобумажные, льняные, шелковые, шерстяные и синтетические ткани, товары из нетканых материалов типа тканей - ленты, тесьма, кружево и другие); кабельная продукция (провода, шнуры, кабели); строительные и отделочные материалы (линолеум, пленка, ковровые покрытия и другие) и другие товары, отпускаемые на метраж<br/>
          5. Товары бытовой химии.<br/><br/>
          <b>Сроки возврата</b><br/>
          - При покупках онлайн сроки возврата составляют 14 календарных дней, где начальной датой отсчета зачастую считается время выхода посылки от зарубежного Поставщика.<br/><br/>
          <b>В течение какого времени можно сделать возврат Товара?</b><br/>
          - В течение 14 календарных дней c момента получения Товара Клиентом (в случае если Товар подлежит возврату по закону).<br/>
          - Клиенту возвращается стоимость Товара за минусом 7.5% банковских расходов и транспортных расходов за возврат Товара. После обработки возвращаемого Товара ее полная стоимость автоматически зачисляется на карту Клиента, с которой проводилась оплата, в течение 20 рабочих дней.<br/><br/>
          <b>Как я могу вернуть Товар?</b><br/>
          - Товар подлежит переслать на адрес Оператора:<br/>
          Юридический адрес: индекс 194044, Россия, г. Санкт-Петербург, ул. Комиссара Смирнова, 8-42<br/>
          Товар не нужно отправлять на адрес Поставщика, в противном случае, Оператор не сможет получить Товар и вернуть деньги согласно условиям Оператора.<br/><br/>
          <b>Что делать, если пришел не тот Товар?</b><br/>
          - У вас есть 14 календарных дней, чтобы уведомить об этом Оператора. Претензии не принимаются после истечения 14 календарных дней с момента получения такого Товара.<br/><br/>
          <b>Когда мне ответит служба поддержки?</b><br/>
          - Сотрудники службы поддержки ответят вам в течение 24 часов после того, как получат ваше обращение. <br/>
        </p>
      </div>
    </section>
  )
}