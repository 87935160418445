import styles from 'pages/faq/HowToOrderPage.module.scss'

export default function FaqPage() {
  return (
    <section>
      <div className={styles.container}>
        <h1>FAQ</h1>
        <p>
          <b>Сколько идет доставка?</b>
          <br/><br/>
          Среднее время доставки товара от момента покупки до его получения:<br/>
          7-10 дней - экспресс доставка<br/>
          10-20 дней - обычная доставка<br/>
          Вы можете забрать свой заказ в пункте самовывоза СДЭК в любом городе России или мы доставим курьером.
          <br/><br/>
          <b>Как проверить оригинальность товара?</b>
          <br/><br/>
          Маркетплейс POIZON, с которого производится закупка, имеет крупную научную базу по аутентификации вещей. После проверки на вещь закрепляется фирменная клипса и вкладывается сертификат о том, что вещь прошла проверку. После поступления товаров на наш склад, мы проводим повторную проверку товаров. После нашего досмотра мы также ставим клипсу, подтверждающую качество и оригинальность товара.
          <br/><br/>
          <b>Что делать в случае брака?</b>
          <br/><br/>
          Качество товара проверяется дважды, поэтому брак исключен. Если так выйдет, что до вас все-таки доехал товар с браком, то мы вернем вам полную стоимость товара.
          <br/><br/>
          <b>Как правильно выбрать свой размер?</b>
          <br/><br/>
          Для того чтобы верно определить размер, советуем смотреть полную размерную сетку, она идет индивидуально к каждой модели обуви. Основные размеры в нашем магазине - это EUR (Европейский). Дополнительно, для справки и сравнения в таблицах, пишутся размеры в US/UK/FR/CHN/JP и сантиметрах.<br/><br/>Обозначения размеров:<br/>
          EUR - европейский<br/>
          US - американский<br/>
          UK - английский<br/>
          FR - французский<br/>
          СHN - китайский<br/>
          JP - японский<br/><br/>
          Примечание:<br/>
          EUR - на 1 деление обозначается больше русского. Например, если у вас русский 42, значит EUR - 43 FR - в основном встречается у Adidas, имеет дольные значения (например 42 2/3). Советуем в обуви Adidas ориентироваться на US/UK или прошлый опыт носки обуви бренда. CHN - очень похожи на значения в сантиметрах, но на самом деле отличаются от обычных сантиметров стельки. Советуем не ориентироваться на китайский размер. US - самое универсальное значения для определения размера в любой обуви, в 97% является самым точным.
          <br/><br/>
          <b>Как измерить длину у стельки?</b>
          <br/><br/>
          Чтобы не ошибиться при выборе кроссовок советуем измерить длину стельки в сантиметрах в обуви, которую вы обычно носите и сравнить с таблицей размеров на сайте.
          <br/><br/>
          <b>Что делать, если товар не подошел?</b>
          <br/><br/>
          Если товар не подошел, то пишите в нашу службу поддержки, мы поможем отправить товар обратно и вернем вам деньги 
        </p>
      </div>
    </section>
  )
}