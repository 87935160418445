import styles from 'pages/faq/HowToOrderPage.module.scss'
import OrderStep1 from "images/order/orderStep1.png"
import OrderStep2 from "images/order/orderStep2.png"
import OrderStep3 from "images/order/orderStep3.png"
import OrderStep4 from "images/order/orderStep4.png"
import OrderStep5 from "images/order/orderStep5.png"
import OrderStep6 from "images/order/orderStep6.png"
import { Link } from 'react-router-dom'

export default function HowToOrderPage() {
  return (
    <section>
      <div className={styles.container}>
        <h1>Как сделать заказ?</h1>
        <p>1. <Link to="/how-to-reg">Пройти регистрацию или авторизироваться</Link></p>
        <p>2. Заполнить данные о себе</p>
        <img src={OrderStep1} alt='guide'></img>
        <p>3. Заполнить данные о местоположении для доставки</p>
        <img src={OrderStep2} alt='guide'></img>
        <img src={OrderStep3} alt='guide'></img>
        <p>4. Добавить выбранный товар в корзину и перейти к ней</p>
        <img src={OrderStep4} alt='guide'></img>
        <img src={OrderStep5} alt='guide'></img>
        <p>5. Нажать кнопку <span>Оформить заказ</span>, выбрать нужный адрес для доставки и оплатить</p>
        <img src={OrderStep6} alt='guide'></img>
      </div>
    </section>
  )
}