import styles from 'pages/faq/PaymentMethods.module.scss'

export default function PaymentMethods() {
  return (
    <section>
      <div className={styles.container}>
        <h1>Способы оплаты</h1>
        <p>Оплата производится с помощью платежной системы Юkassa, которая поддерживает более 10 способов
            оплаты, среди которых: банковские карты, электронные кошельки, СБП и многие другие
        </p>
      </div>
    </section>
  )
}