import axios from "axios";
import store from "redux-store/store";
import { SERVER_ADDRESS, SERVER_API_VERSION } from "./config";
import { setStateLogin, setStateLogout } from "redux-store/user-auth";
import { viewAlert } from "redux-store/notifications";
import validateTokens from "./auth";

interface IUserLogin {
  email: string;
  password: string;
}

export interface IUserProfile {
  first_name: string;
  last_name: string;
  middle_name: string;
  phone: string;
  gender: boolean;
}

export interface IUserParam {
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  phone?: string;
  gender?: boolean;
  password?: string;
}

export async function userLogin(params: IUserLogin) {
  try {
    const response = await axios.post(`${SERVER_ADDRESS}${SERVER_API_VERSION}users/login/`, params);
    store.dispatch(setStateLogin({ access: response.data.tokens.access, refresh: response.data.tokens.refresh }));
    store.dispatch(viewAlert({ text: "Вы успешно вошли в аккаунт", type: "success" }));
    return response;
  } catch (error: any) {
    if (error.response && ((error.response.status === 405) || (error.response.status === 404))) {
      if (error.response.status === 405) {
        store.dispatch(viewAlert({ text: "Для входа в аккаунт необходимо активировать его через письмо, отправленное на указанную вами при регистрации электронную почту", type: "warning" }));
      } else if (error.response.status === 404) {
        store.dispatch(viewAlert({ text: "Неверный логин или пароль", type: "error" }));
      }
    } else {
      store.dispatch(viewAlert({ text: "Ошибка: " + error.message, type: "error" }));
    }

    throw error;
  }
}

export async function userRegister(params: IUserLogin) {
  try {
    const response = await axios.post(`${SERVER_ADDRESS}${SERVER_API_VERSION}users/register/`, params);
    store.dispatch(viewAlert({text: "Для завершения регистрации необходимо активировать аккаунт через письмо, отправленное на указанную вами электронную почту", type: "warning"}));
    return response;
  } catch (error: any) {
    if (error.response && (error.response.status === 409)) {
      store.dispatch(viewAlert({text: "Аккаунт с такой почтой уже зарегистрирован", type: "error"}));
    } else {
      store.dispatch(viewAlert({ text: "Ошибка: " + error.message, type: "error" }));
    }

    throw error;
  }
}

export async function userActivateAccount(token: string) {
  try {
    const response = await axios.get(`${SERVER_ADDRESS}${SERVER_API_VERSION}users/activate/${token}`);
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function userResetPassword(password: string, token: string) {
  try {
    const response = await axios.put(
      `${SERVER_ADDRESS}${SERVER_API_VERSION}users/reset-password/`, {new_password: password},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function userRecoverPassword(params: {email: string}) {
  try {
    const response = await axios.post(`${SERVER_ADDRESS}${SERVER_API_VERSION}users/recover-password/`, params);
    store.dispatch(viewAlert({text: "На указанную почту отправлено письмо с инструкцией по восстановлению пароля", type: "warning"}));
    return response;
  } catch (error: any) {
    if (error.response && (error.response.status === 404)) {
      store.dispatch(viewAlert({text: "Аккаунт с указанной почтой не найден", type: "error"}));
    } else {
      store.dispatch(viewAlert({ text: "Ошибка: " + error.message, type: "error" }));
    }

    throw error;
  }
}

export async function userUpdateToken() {
  try {
    const response = await axios.post(`${SERVER_ADDRESS}${SERVER_API_VERSION}users/refresh-token/`, {refresh: store.getState().auth.authTokens.refresh});
    store.dispatch(setStateLogin({ access: response.data.access, refresh: response.data.refresh }));
  } catch (error: any) {
    store.dispatch(setStateLogout());
    throw error;
  }
}

export async function userGetProfile() {
  try {
    await validateTokens();

    const response = await axios.get(
      `${SERVER_ADDRESS}${SERVER_API_VERSION}users/profile/`, 
      { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function userUpdateProfile(param: IUserParam) {
  try {
    await validateTokens();

    const response = await axios.put(
      `${SERVER_ADDRESS}${SERVER_API_VERSION}users/profile/`, param,
      { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function userUpdatePassword({old_password, new_password}: {old_password: string, new_password: string}) {
  try {
    await validateTokens();
    
    const response = await axios.put(
      `${SERVER_ADDRESS}${SERVER_API_VERSION}users/profile/`, {old_password: old_password, new_password: new_password},
      { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    if (error.response && (error.response.status === 406)) {
      store.dispatch(viewAlert({ text: "Неверный старый пароль", type: "error" }));
    } else {
      store.dispatch(viewAlert({ text: "Ошибка: " + error.message, type: "error" }));
    }

    throw error;
  }
}