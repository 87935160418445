import axios from "axios";
import store from "redux-store/store";
import { SERVER_ADDRESS, SERVER_API_VERSION } from "./config";
import validateTokens from "./auth";
import { setUserData } from "redux-store/user-auth";

export interface IBasket {
    size: string;
    color?: string;
    id?: number;
    price: number;
    count: number;
    product_id: number;
}

interface IBusketUpdate {
  id: number;
  operation: "plus" | "minus";
}

export async function basketAddItem(data: IBasket) {
  try {
    await validateTokens();

    const response = await axios.post(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}basket/`, 
    data, 
    {  headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` }, }
    );

    const prev_basket_count = store.getState().auth.userData.basket;
    store.dispatch(setUserData({basket: prev_basket_count + 1}))

    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function basketGet() {
  try {
    await validateTokens();

    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}basket/`, 
    {  headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` }, }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function basketUpdateCount(data: IBusketUpdate) {
  try {
    await validateTokens();

    const response = await axios.put(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}basket/`, data,
    {  headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` }, }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function basketDeleteItem(basket_item_id: number) {
  try {
    await validateTokens();
    
    const response = await axios.delete(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}basket/`,
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` }, 
      data: { id: basket_item_id } 
    }
    );

    const prev_basket_count = store.getState().auth.userData.basket;
    store.dispatch(setUserData({basket: prev_basket_count - 1}))

    return response;
  } catch (error: any) {
    throw error;
  }
}