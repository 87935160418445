import axios from "axios";
import store from "redux-store/store";
import { SERVER_ADDRESS, SERVER_API_VERSION } from "./config";
import validateTokens from "./auth";
import { setUserData } from "redux-store/user-auth";

export interface IFavourite {
  id?: number;
  user_id: number;
  product_id: number;
}

export async function getFavourites() {
  try {
    await validateTokens();

    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}favourite/`, 
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function checkProductInFavourites(product_id: number) {
  try {
    await validateTokens();

    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}favourite/check?product_id=${product_id}`, 
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function deleteFavourite(product_id: number) {
  try {
    await validateTokens();

    const response = await axios.delete(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}favourite/`,
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` }, 
      data: { id: product_id } 
    }
    );

    const prev_favourites_count = store.getState().auth.userData.favourites;
    store.dispatch(setUserData({favourites: prev_favourites_count - 1}))

    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function addToFavourite(product_id: number) {
  try {
    await validateTokens();
    
    const response = await axios.post(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}favourite/`, {"product_id": product_id},
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );

    const prev_favourites_count = store.getState().auth.userData.favourites;
    store.dispatch(setUserData({favourites: prev_favourites_count + 1}))

    return response;
  } catch (error: any) {
    throw error;
  }
}