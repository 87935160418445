import styles from 'components/Footer.module.scss'
import IconVk from 'icons/icon-vk.svg'
import IconTg from 'icons/icon-telegram.svg'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className={styles.container}>
      <div className={styles.links_block}>
        <h1>Покупателям</h1>
        <Link to="/how-to-order">Как сделать заказ?</Link>
        <Link to="/payment-methods">Способы оплаты</Link>
        <Link to="/delivery">Доставка и возврат</Link>
      </div>

      <div className={styles.links_block}>
        <h1>Компания</h1>
        <Link to="/about">О нас</Link>
        <Link to="/requisites">Реквизиты</Link>
        <Link to="/faq">FAQ</Link>
      </div>

      <div className={styles.social_block}>
        <Link to="">
          <img className={styles.icon_vk} src={IconVk} alt='vk' />
        </Link>
        <Link to="">
          <img className={styles.icon_tg} src={IconTg} alt='telegram' />
        </Link>
      </div>
    </div>
  )
}