import styles from 'pages/MainPage.module.scss'
import TopBlockImageLeft from "images/image-top-block-left.png"
import TopBlockImageRight from "images/image-top-block-right.png"
import ImageBlock from "images/image-main-bottom.png"
import IconRightArrow from 'icons/icon-arrow-right.svg'
import Card from 'components/Card';
import { Link, useNavigate } from 'react-router-dom';
import { IProduct, productsGetPopular } from 'server-api/product'
import { useEffect, useState } from 'react'
import Loader from 'components/Loader'

const TopBlock = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.top_block}>
      <div className={styles.left_block}>
        <h1>Мы классный магазин, покупайте кроссовки и не только ;)</h1>
        <button onClick={() => navigate("/products?mcat=/Apparel")}>
          Перейти в каталог
          <img src={IconRightArrow} alt='arrow right'/>
        </button>
      </div>
      <img src={TopBlockImageLeft} alt='sneakers'/>
      <img src={TopBlockImageRight} alt='sneakers'/>
    </div>
  );
}

const MayLikeBlock = () => {
  const [popularProducts, setPopularProducts] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await productsGetPopular(5)
      .then((resp) => setPopularProducts(resp.data as IProduct[]))
      .catch((error) => console.log("Nothing popular, error:" + error));

      setIsLoading(false);
    }) ()
  }, []);

  return (
    <div className={styles.may_like_block}>
      <h1>Тебе понравится</h1>
      <div className={styles.cards_container}>
      {
       isLoading ? <Loader />
       :
        popularProducts.length > 0 ? popularProducts.map((item) => 
          <Card 
            id={item.id}
            key={item.id}
            title={item.title}
            brand={item.brand}
            main_category_id={item.main_category_id}
            category_id={item.category_id}
            sub_category_id={item.sub_category_id}
            article={item.article}
            images={item.images}
            data={item.data}
            price={item.price}
          />
        ) : <p>Ничего не найдено</p>
      }
      </div>
    </div>
  );
}

const CaregoriesBlock = () => {
  return (
    <div className={styles.categories_block}>
      <ul>
        <li><Link to="/products?mcat=clothes">Одежда</Link></li>
        <li><Link to="/profile?mcat=footwear">Обувь</Link></li>
        <li><Link to="/profile?mcat=accessories">Аксессуары</Link></li>
        <li><Link to="/products?scat=outerwear">Верхняя одежда</Link></li>          
      </ul>

      <ul>
        <li><Link to="/products?scat=shoes">Ботинки</Link></li>
        <li><Link to="/products?cat=slippers">Тапки</Link></li>
        <li><Link to="/products?scat=sneakers">Кроссовки</Link></li>
      </ul>

      <ul>
        <li><Link to="/products?cat=t-shirts">Футболки</Link></li>
        <li><Link to="/products?cat=hoodies">Худи</Link></li>
        <li><Link to="/products?cat=sweaters">Свитеры</Link></li>
      </ul>
    </div>
  );
}

export default function MainPage() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <TopBlock />
        <MayLikeBlock />
        <CaregoriesBlock />
        <img className={styles.image_block} src={ImageBlock} alt='image'/>
      </div>
    </div>
  )
}