import { jwtDecode } from "jwt-decode";
import store from "redux-store/store";
import { IToken, setStateLogout } from "redux-store/user-auth";
import { userUpdateToken } from "./users";

const validateTokens = async () => {
  const tokens = store.getState().auth.authTokens;

  if (tokens.refresh !== null){
    const isExpiredAccess = Date.now() >= ((jwtDecode(tokens.access as string) as IToken).exp * 1000);

    if (isExpiredAccess) {
      const isExpiredRefresh = Date.now() >= ((jwtDecode(tokens.refresh as string) as IToken).exp * 1000);

      if (!isExpiredRefresh) {
        try {
          const resp = await userUpdateToken();
        } catch (error: any) {
          store.dispatch(setStateLogout())
          throw error;
        }
      } else {
        store.dispatch(setStateLogout());
        throw new Error("Tokens not valide");
      }
    } else {
      return true;
    }
  } else {
    throw new Error("Tokens are empty");
  }
}

export default validateTokens;