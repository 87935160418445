import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store';
import { jwtDecode } from 'jwt-decode';

export interface IToken {
  token_type: string;
  exp: number;
  iat: number;
  jti: number;
  user_id: number;
  email: string;
}

interface ITokens{
  refresh: string | null;
  access: string | null
}

interface IUserData {
  favourites: number;
  basket: number;
}

interface IAuthState {
  userMail: string | null;
  authTokens: ITokens;
  userData: IUserData
}
  
const initialState: IAuthState = {
  authTokens: localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens') as string) : { refresh: null, access: null },
  userMail: localStorage.getItem('authTokens') ? (jwtDecode(JSON.parse(localStorage.getItem('authTokens') as string).access) as IToken)?.email : null,
  userData: {favourites: 0, basket: 0}
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStateLogin: (state, data: {payload: ITokens, type: any}) => {
      state.userMail = (jwtDecode(data.payload.access as string) as IToken).email;
      state.authTokens = data.payload;
      localStorage.setItem('authTokens', JSON.stringify(data.payload));
    },
    setStateLogout: (state) => {
      state.userMail = null;
      state.authTokens = {refresh: null, access: null};
      state.userData = {favourites: 0, basket: 0};
      localStorage.removeItem('authTokens');
    },
    setUserData: (state, data: {payload: {favourites?: number, basket?: number}, type: any}) => {
      data.payload.favourites !== undefined ? 
        state.userData = {...state.userData, favourites: data.payload.favourites} 
      : 
      data.payload.basket !== undefined ?
        state.userData = {...state.userData, basket: data.payload.basket as number}
      :
        console.log("UserData error: " + JSON.stringify(data.payload));
    }
  },
})

export const { setStateLogin, setStateLogout, setUserData } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;