import axios from "axios";
import store from "redux-store/store";
import { SERVER_ADDRESS, SERVER_API_VERSION } from "./config";
import validateTokens from "./auth";

export interface IAddress {
  id?: number;
	country: string;
	region: string;
	city: string;
	street: string;
	home: string;
	postcode: number;
	isDefault: boolean;
}

export async function getAddresses() {
  try {
    await validateTokens();

    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}addresses/`, 
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function createAddress(address: IAddress) {
  try {
    await validateTokens();

    const response = await axios.post(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}addresses/`, address,
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function deleteAddress(address_id: number) {
  try {
    await validateTokens();

    const response = await axios.delete(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}addresses/`,
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` }, 
      data: { id: address_id } 
    }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function updateAddress(address: IAddress) {
  try {
    await validateTokens();
    
    const response = await axios.put(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}addresses/`, address,
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}