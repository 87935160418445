import axios from 'axios';
import Loader from 'components/Loader';
import styles from 'pages/auth/RecoverPasswordPage.module.scss';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'redux-store/hooks';
import { viewAlert } from 'redux-store/notifications';
import { SERVER_ADDRESS, SERVER_API_VERSION } from 'server-api/config';
import { userRecoverPassword, userResetPassword, userUpdatePassword } from 'server-api/users';

const RecoverPasswordPage = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const token = searchParams.get("token");

  const handleCheckboxPasswordChange = () => {
    setViewPassword(prevState => !prevState);
  }

  const handleOnSubmitMail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElements = e.currentTarget.elements as typeof e.currentTarget.elements & {
      email: HTMLInputElement,
    };

    setIsLoading(true);

    await userRecoverPassword({ email: formElements.email.value })
    .then (() => navigate("/"))
    .catch((error) => setIsLoading(false));
  }

  const handleOnSubmitRecover = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElements = e.currentTarget.elements as typeof e.currentTarget.elements & {
      old_pswd: HTMLInputElement,
      password1: HTMLInputElement,
      password2: HTMLInputElement
    };

    if (formElements.password1.value !== formElements.password2.value) {
      dispatch(viewAlert({text: "Новый пароль должен совпадать", type: "warning"}));
      return;
    }

    setIsLoading(true);

    await userResetPassword(formElements.password1.value, token as string)
      .then((resp) => {
        dispatch(viewAlert({text: "Пароль успешно изменен", type: "success"}));
        navigate("/authorization");
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Произошла ошибка, попробуйте еще раз", type: "warning" }));
        setIsLoading(false);
      })
  }

  return (
    <section>
      <div className={styles.container}>
        {
          isLoading ? <Loader />
          :
            <>
              {
                token ? 
                  <>
                    <h1>Восстановление пароля</h1>

                    <form onSubmit={handleOnSubmitRecover}>
                      <input 
                        name='password1' 
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                        title="Пароль должен быть 8+ символов и должен содержать минимум одну заглавную букву, одну обычную и одну цифру"
                        required 
                        minLength={8} 
                        maxLength={30} 
                        type={ viewPassword ? "text" : "password" }
                        placeholder="Введите новый пароль" 
                      />

                      <input 
                        name='password2' 
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                        title="Пароль должен быть 8+ символов и должен содержать минимум одну заглавную букву, одну обычную и одну цифру"
                        required 
                        minLength={8} 
                        maxLength={30} 
                        type={ viewPassword ? "text" : "password" }
                        placeholder="Повторите новый пароль" 
                      />

                      <div className={styles.checkbox_block}>
                        <input 
                          name='view-password' 
                          type="checkbox" 
                          placeholder="Показать пароль" 
                          checked={viewPassword} 
                          onChange={handleCheckboxPasswordChange}
                        />
                        <label htmlFor="view-password">Показать пароль</label>
                      </div>

                      <button type='submit'>Отправить</button>
                    </form>
                  </>
                :
                  <>
                    <h1>Восстановление пароля</h1>
                    <p>На указанную вами почту придет письмо с инструкцией по восстановлению аккаунта</p>

                    <form onSubmit={handleOnSubmitMail}>
                      <input 
                        name='email' 
                        type="email" 
                        pattern="[^@\s]+@[^@\s]+"
                        maxLength={30}
                        required
                        placeholder="Введите email" 
                      />

                      <button type='submit'>Отправить</button>
                    </form>
                  </>
              }
            </>
        }
      </div>
    </section>
  );
}

export default RecoverPasswordPage;
