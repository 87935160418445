import Loader from 'components/Loader';
import styles from 'pages/auth/AuthPage.module.scss';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { userLogin } from 'server-api/users';

const AuthPage = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const next_url = searchParams.get("next");

  const handleCheckboxChange = () => {
    setViewPassword(!viewPassword);
  }

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElements = e.currentTarget.elements as typeof e.currentTarget.elements & {
      mail: HTMLInputElement,
      password: HTMLInputElement
    };

    setIsLoading(true);

    await userLogin({ email: formElements.mail.value, password: formElements.password.value })
    .then (() => {
      navigate(next_url ? next_url : "/");
    })
    .catch((error) => setIsLoading(false));
  }

  return (
    <section>
      <div className={styles.container}>
        {
          isLoading ? <Loader />
          :
            <>
              <h1>Авторизация</h1>

              <form onSubmit={handleOnSubmit}>
                <input 
                  name='mail' 
                  type="email" 
                  pattern="[^@\s]+@[^@\s]+"
                  maxLength={30}
                  required
                  placeholder="Введите email" 
                />
                <input 
                  name='password' 
                  type={ viewPassword ? "text" : "password" }
                  minLength={8} 
                  maxLength={30}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                  title="Пароль должен быть 8+ символов и должен содержать минимум одну заглавную букву, одну обычную и одну цифру"
                  required
                  placeholder="Введите пароль" 
                />
                <div className={styles.checkbox_block}>
                  <input 
                    name='viewPassword' 
                    type="checkbox" 
                    placeholder="Показать пароль" 
                    checked={viewPassword} 
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor="viewPassword">Показать пароль</label>
                </div>
                <button type="submit">Войти</button>
              </form>

              <div className={styles.link_block}>
                <p>Впервые на сайте?</p>
                <Link to="/registration">Зарегистрироваться</Link>
              </div>

              <div className={styles.link_block}>
                <p>Забыли пароль?</p>
                <Link to="/recover-password">Восстановить</Link>
              </div>
            </>
        }
      </div>
    </section>
  );
}

export default AuthPage;
