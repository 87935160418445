import styles from "pages/auth/AuthPage.module.scss"
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userActivateAccount } from "server-api/users";

export default function ActivateUserPage () {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const [response, setResponse] = useState(0);

  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (token?.length === 0)
      navigate("/");

    (async () => {
      setIsLoading(true);

      await userActivateAccount(token as string)
      .then((resp) => setResponse(1))
      .catch((error) => {setResponse(0)});

      setIsLoading(false);
    }) ()
  }, [token]);

  return (
    <section>
      <div className={styles.container}>
        {
          isLoading ? <Loader />
          :
          response == 0 ? 
            <>
              <p>Ссылка для подтверждения аккаунта неверная</p>
            </>
          :
            <>
              <p style={{"paddingBottom": "10px", "width": "100%", "textAlign": "center"}}>Ваш аккаунт успешно подтвержден</p>
              <button onClick={() => navigate("/authorization")}>Авторизироваться</button>
            </>
        }
      </div>
    </section>
  )
}