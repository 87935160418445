import Card from 'components/Card';
import styles from 'pages/ProductView.module.scss';
import IconLike from 'icons/icon-like.svg'
import IconLikeFilled from 'icons/icon-like-filled.svg'
import IconGlobal from 'icons/icon-global.svg'
import IconFastDelivery from 'icons/icon-fast-delivery.svg'
import IconSecure from 'icons/icon-secure.svg'
import IconStar from 'icons/icon-star.svg'
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IColorSize, IProduct, productGetById, productsSimilar } from 'server-api/product';
import { useAppDispatch, useAppSelector } from 'redux-store/hooks';
import { viewAlert } from 'redux-store/notifications';
import { basketAddItem } from 'server-api/basket';
import { addToFavourite, checkProductInFavourites, deleteFavourite } from 'server-api/favourite';
import Loader, { MiniLoader } from 'components/Loader';

const hasReviews = 1;

const ShopReview = () => {
  return (
    <section className={styles.section_delivery}>
        <div className={styles.item}>
          <img src={IconFastDelivery} alt='delivery' />
          <div className={styles.description_text}>
            <h1>Быстрая доставка</h1>
            <p>Доставка занимает от 5 до 20 дней</p>
          </div>
        </div>

        <div className={styles.item}>
          <img src={IconGlobal} alt='global' />
          <div className={styles.description_text}>
            <h1>Глобальность</h1>
            <p>Работаем по всему СНГ и всей России</p>
          </div>
        </div>

        <div className={styles.item}>
          <img src={IconSecure} alt='secure' />
          <div className={styles.description_text}>
            <h1>Оригинальная продукция</h1>
            <p>Мы продаем только оригинальную продукцию</p>
          </div>
        </div>
      </section>
  )
}

const Review = () => {
  return (
    <div className={styles.review}>
      <div className={styles.review_header}>
        <h2>Кирилл C.</h2>
        <p>01.08.2024 в 12:11</p>
        <div className={styles.rating}>
          <img className={styles.star_liked} src={IconStar} alt='star' />
          <img className={styles.star_liked} src={IconStar} alt='star' />
          <img className={styles.star_liked} src={IconStar} alt='star' />
          <img className={styles.star_unliked} src={IconStar} alt='star' />
          <img className={styles.star_unliked} src={IconStar} alt='star' />
        </div>
      </div>
      <p>Очень хорошие кроссовки! Жене понравились, выебите меня в рот. Очень хочу такие жи кросовки но использованне (чтобы пятки лизать)</p>
    </div>
  );
}

const ReviewsSection = () => {
  return (
    <section className={styles.section_reviews}>
      <h1>Отзывы</h1>
      {
        hasReviews ?
          <>
            <div className={styles.scors_container}>
              <div className={styles.reviews}>
                <Review />
                <Review />
                <Review />
                <Review />
              </div>

              <div className={styles.total_rating}>
                <div className={styles.scors_header}>
                  <h1>Рейтинг товара</h1>

                  <div className={styles.rating}>
                    <img className={styles.star_liked} src={IconStar} alt='star' />
                    <img className={styles.star_liked} src={IconStar} alt='star' />
                    <img className={styles.star_liked} src={IconStar} alt='star' />
                    <img className={styles.star_liked} src={IconStar} alt='star' />
                    <img className={styles.star_unliked} src={IconStar} alt='star' />
                  </div>
                </div>
                
                <p><b>4.1 / 5</b> средняя оценка, основана на 3 отзывах</p>
                
                <span></span>

                <div className={styles.scors_rating_container}>
                  <div className={styles.scors_rating_column}>
                    <p>5 звезд</p>
                    <p>4 звезды</p>
                    <p>3 звезды</p>
                    <p>2 звезды</p>
                    <p>1 звезда</p>
                  </div>

                  <div className={styles.scors_rating_column}>
                    <div><span style={{display: "inline-block", width: "70%", height: "100%", background: "#A5529C"}}/></div>
                    <div><span style={{display: "inline-block", width: "40%", height: "100%", background: "#A5529C"}}/></div>
                    <div><span style={{display: "inline-block", width: "20%", height: "100%", background: "#A5529C"}}/></div>
                    <div><span style={{display: "inline-block", width: "10%", height: "100%", background: "#A5529C"}}/></div>
                    <div><span style={{display: "inline-block", width: "5%", height: "100%", background: "#A5529C"}}/></div>
                  </div>

                  <div className={styles.scors_rating_column}>
                    <p>120</p>
                    <p>64</p>
                    <p>44</p>
                    <p>12</p>
                    <p>3</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        : <p>Отзывов пока нет</p>
      }
    </section>
  );
}

interface IOtherProductSection {
  subcat_id?: number;
  brand_title?: string;
  product_id: number
  title: string;
}

const OtherProductsSection = (data: IOtherProductSection) => {
  const [similarProducts, setSimilarProducts] = useState<IProduct[]>([]);
  const [count, setCount] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (page: number) => {
    await productsSimilar({
      filterBy: data.subcat_id ? "subcategory_id" : "brand_title",
      value: data.subcat_id ? String(data.subcat_id) : data.brand_title as string,
      count: page,
      product_id: data.product_id
    })
    .then((resp) => {
      if (page != 1)
        setSimilarProducts([...similarProducts, ...(resp.data as IProduct[])]);
      else
        setSimilarProducts(resp.data as IProduct[]);
    })
    .catch((error) => console.log("Error" + error));

    setIsLoading(false);
  }

  useEffect(() => {
    if ((data.subcat_id === undefined) && (data.brand_title === undefined) || (count == 1)) 
      return;

    (async () => {
      await fetchData(count);
    }) ()
  }, [count]);

  useEffect(() => {
    if ((data.subcat_id === undefined) && (data.brand_title === undefined)) 
      return;

    (async () => {
      await fetchData(1);
    }) ()

    setCount(1);
  }, [data.subcat_id, data.brand_title, data.product_id]);

  return (
    <section className={styles.section_similar}>
      <h1>{data.title}</h1>
      
      {
        isLoading ? <Loader />
        :
          <>
            <div className={styles.cards_container}>
              {
                similarProducts.length === 0 ?
                  <p>Ничего не найдено</p>
                :
                  similarProducts.map((item) => 
                    <Card 
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      brand={item.brand}
                      main_category_id={item.main_category_id}
                      category_id={item.category_id}
                      sub_category_id={item.sub_category_id}
                      article={item.article}
                      images={item.images}
                      data={item.data}
                      price={item.price}
                    />
                  )
              }
            </div>
            
            {
              similarProducts.length === (count * 5) ? 
                <button onClick={() => setCount(prev => prev + 1)}>Показать еще</button>
              : 
                <></>
            }
          </>
      }
    </section>
  )
}

const ProductView = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userMail = useAppSelector((state) => state.auth.userMail);
  const currentRate = useAppSelector((state) => state.adminData.current_rate);

  const [isFavourite, setIsFavourite] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>();
  const [productData, setProductData] = useState<IProduct>();
  const [currentValues, setCurrentValues] = useState<{price: number, color: string, size: string}>({price: 0, color: "", size: ""});
  const [isLoading, setIsLoading] = useState(0);
  const [basketButtonState, setBasketButtonState] = useState<0 | 1 | 2>(0);

  const product_id = searchParams.get("id");

  const updateColor = (new_color: string) => {
    (productData?.data as IColorSize)[new_color].map((sizeObj, index) => (
      Object.entries(sizeObj).map(([size, value]) => (
         index === 0 ? setCurrentValues({color: new_color, size: size, price: value}) : {}
      ))
    ))
  }

  const updateColorSize = (new_size: string) => {
    (productData?.data as IColorSize)[currentValues.color].map((sizeObj, index) => (
      Object.entries(sizeObj).map(([size, value]) => (
        size === new_size ? setCurrentValues(prev => ({...prev, size: new_size, price: value})) : {}
      ))
    ))
  }

  const addToBasket = async () => {
    if (userMail === null) {
      dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
      navigate("/authorization?next=/product?id=" + productData?.id);
      return;
    } else if (basketButtonState === 2) {
      dispatch(viewAlert({ text: "Товар уже в корзине", type: "warning" }));
      return;
    }
      
    const basketItem = {
      price: currentValues.price, 
      color: currentValues.color,
      size: currentValues.size,
      count: 1,
      product_id: Number.parseInt(product_id as string)
    };

    if (currentValues.color !== "") basketItem["color"] = currentValues.color;

    setBasketButtonState(1);

    await basketAddItem(basketItem)
    .then (() => {})
    .catch((error) => dispatch(viewAlert({text: "Ошибка: " + error, type: "error"})));

    setBasketButtonState(2);
  }

  const handleOnClick = async (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (userMail === null) {
      dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
      navigate("/authorization?next=" + location.pathname + location.search);
      return;
    }

    if (isFavourite) {
      await deleteFavourite(productData?.id as number)
      .then((resp) => setIsFavourite(false))
      .catch((error) => dispatch(viewAlert({text: "Ошибка: " + error, type: "error"})));
    } else {
      await addToFavourite(productData?.id as number)
      .then((resp) => setIsFavourite(true))
      .catch((error) => dispatch(viewAlert({text: "Ошибка: " + error, type: "error"})));
    }
  }

  useEffect(() => {
    if ((userMail !== null) && (productData?.id)) {
      (async () => {
        await checkProductInFavourites(productData?.id as number)
        .then((resp) => resp.data.response === true ? setIsFavourite(true) : setIsFavourite(false))
        .catch((error) => {});

        setIsLoading(prev => prev + 1);
      }) ()
    } else {
      setIsLoading(prev => prev + 1);
    }
  }, [isFavourite, productData?.id]);

  useEffect(() => {
    (async () => {
      await productGetById(product_id || "-1")
      .then((resp) => {
        setProductData(resp.data as IProduct);
        setSelectedImage(resp.data.images.Images[0]);
        setCurrentValues(
          {
            color: resp.data.data.Sizes ? "" : Object.keys(resp.data.data as IColorSize)[0], 
            price: resp.data.price, 
            size: Object.keys(resp.data.data[Object.keys(resp.data.data as IColorSize)[0]][0])[0]
          }
        );
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        dispatch(viewAlert({text: "Продукт с таким id не найден", type: "error"}));
        navigate("/");
      });

      setIsLoading(prev => prev + 1);
    }) ()
  }, [product_id]);

  return (
    <section className={styles.product_section}>
      {
        (isLoading < 2) ? <Loader />
        :
          <>
            <section className={styles.section_product}>
              <div className={styles.mini_images_container}>
                {
                  productData?.images.Images.map((item) => 
                    <img 
                      key={item} 
                      src={item} 
                      alt='image' 
                      className={item === selectedImage ? styles.selected : ""}
                      onClick={() => setSelectedImage(item)}
                    />
                  )
                }
              </div>
              
              <div className={styles.big_image_container}>
                <img className={styles.big_image} src={selectedImage} alt='image'/>
                <img 
                  className={styles.like_button} 
                  src={isFavourite ? IconLikeFilled : IconLike}  
                  onClick={(e) => handleOnClick(e)}
                  alt='like'
                />  
              </div>
            
              <div className={styles.product_description_container}>
                <h1>{productData?.title}</h1>
                <p>Бренд: {productData?.brand}</p>
                <p>Артикул: {productData?.article}</p>

                <form>
                  {
                    !productData?.data.Sizes ?
                    <>
                      <p>Цвет</p>

                      <div>
                        {
                          productData?.data ? Object.keys(productData?.data).map((color, index) => 
                            <React.Fragment key={color}>
                              <input 
                                id={color} 
                                name="color" 
                                type="radio" 
                                value={color} 
                                onChange={(e) => updateColor(color)}
                                defaultChecked={index === 0 ? true : false}
                              />
                              <label htmlFor={color}>{color}</label>
                            </React.Fragment>
                          ) : <></>
                        }
                      </div>

                      <p>Размер</p>
                      
                      <div>
                        {
                          productData?.data ? Object.keys(productData?.data).map((color, index) => 
                            <React.Fragment key={color + index}>
                              {
                                color === currentValues.color ? 
                                (productData?.data as IColorSize)[color].map((sizeObj, index) => (
                                  Object.entries(sizeObj).map(([size, value]) => (
                                    <React.Fragment key={size}>
                                      <input 
                                        id={size}
                                        name="size" 
                                        type="radio" 
                                        value={value}
                                        onChange={(e) => updateColorSize(size)}
                                        defaultChecked={index === 0 ? true : false}
                                      />
                                      <label htmlFor={size}>{size}</label>
                                    </React.Fragment>
                                  ))
                                )) : <></>
                              }
                            </React.Fragment>
                            
                          ) : <></>
                        }
                      </div>
                    </>
                    : 
                    <>
                      <p>Размер</p>

                      <div>
                        {
                          productData.data.Sizes.map((sizeObj, index) => 
                            <React.Fragment key={Object.keys(sizeObj)[0]}>
                              <input 
                                id={Object.keys(sizeObj)[0]} 
                                name="size" 
                                type="radio" 
                                value={sizeObj[Object.keys(sizeObj)[0]]}
                                onChange={(e) => setCurrentValues(prev => ({...prev, price: Number.parseInt(e.target.value), size: Object.keys(sizeObj)[0]}))}
                                defaultChecked={index === 0 ? true : false}
                              />
                              <label htmlFor={Object.keys(sizeObj)[0]}>{Object.keys(sizeObj)[0]}</label>
                            </React.Fragment>
                          )
                        }
                      </div>
                    </>
                  }
                </form>

                <span></span>

                <h2>{currentValues.price * currentRate} ₽</h2>
                  
                <div className={styles.buttons_container}>
                  <button 
                    onClick={() => addToBasket()}
                    className={basketButtonState === 0 ? "" : styles.button_outline}
                  >
                    {
                      basketButtonState === 0 ? "Добавить в корзину" : basketButtonState === 1 ? <MiniLoader /> : "В корзине"
                    }
                  </button>
                </div>
              </div>

              <ShopReview />
            </section>

            {/* <ReviewsSection /> */}

            <OtherProductsSection 
              subcat_id={productData?.sub_category_id as number} 
              product_id={productData?.id as number}
              title="Похожие товары"
            />
            
            <OtherProductsSection 
              brand_title={productData?.brand} 
              product_id={productData?.id as number}
              title="Товары от данного производителя"
            />
          </>
      }
      
    </section>
  );
}

export default ProductView;
