import { IClothes } from 'components/Header';
import styles from 'components/modals/MobileMenu.module.scss'
import IconClose from 'icons/icon-close.svg'
import IconRightArrow from 'icons/icon-right-arrow-mini.svg'
import IconSearch from 'icons/icon-search.svg'
import React, { useRef, useState } from 'react';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

interface IMobileMenu {
  CategoriesClothes: IClothes[];
  CategoriesShoes: IClothes[];
  CategoriesAccessories: IClothes[];
  closeModal: () => void;
}

const MobileMenuBlock = ({data, title, closeModal}: {data: IClothes[], title: string, closeModal: () => void}) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  
  return (
    <div className={styles.menu_item}>
      <div 
        className={styles.menu_button}
        onClick={() => setOpenMenu(prev => !prev)}
      >
        <p>{title}</p>
        <img src={IconRightArrow} alt='arrow' />
      </div>

      <div 
        className={styles.menu_item_links} 
        ref={contentRef} 
        style={openMenu ? { height: contentRef.current?.scrollHeight + "px" } : { height: "0px" }}
      >
        {
          data.map((item) => 
            <Link key={item.link} to={item.link} onClick={() => closeModal()}>
              <p>{item.isBold ? <b>{item.name}</b> : item.name}</p>
            </Link>
          )
        }
      </div>
    </div>
  )
}

export default function MobileMenu(data: IMobileMenu) {
  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      search();
    }
  }  

  const search = () => {
    if (searchRef?.current?.value === "") 
      return;
    
    data.closeModal();
    navigate({pathname: "/products", search: createSearchParams({s: searchRef?.current?.value as string}).toString()});
  }

  return (
    <div className={styles.container}>
      <img 
        src={IconClose} 
        onClick={() => data.closeModal()}
        alt='user' 
      />

      <div className={styles.search}>
        <input type='text' defaultValue="" placeholder='Найти' onKeyUp={handleKeyUp} ref={searchRef} autoComplete="on"/>
        <img src={IconSearch} alt='search' onClick={() => search()} />
      </div>

      <div className={styles.menu}>
        <MobileMenuBlock data={data.CategoriesClothes} title='Одежда' closeModal={data.closeModal}/>
        <MobileMenuBlock data={data.CategoriesShoes} title='Обувь' closeModal={data.closeModal}/>
        <MobileMenuBlock data={data.CategoriesAccessories} title='Аксессуары' closeModal={data.closeModal}/>
      </div>
    </div>
  )
}