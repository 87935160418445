import styles from 'pages/ErrorPage.module.scss'
import Footer from "components/Footer";
import Header from "components/Header";
import { Link } from 'react-router-dom';

export default function ErrorPage() {
  return (
    <div className={styles.container}>
      <div className={styles.content_block}>
        <h1>404</h1>
        <p>Страница не найдена :(</p>
        <Link to="/">
          <button>На главную</button>
        </Link>
      </div>
    </div>
  )
}