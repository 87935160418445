import styles from "pages/user/UserAddresses.module.scss"
import "components/modals/ModalsGlobalStyles.css"
import IconEdit from "icons/icon-edit.svg"
import IconDelete from "icons/icon-delete.svg"
import { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import AddressModal from "components/modals/AddressModal";
import { IAddress, deleteAddress, getAddresses } from "server-api/addresses";
import { useAppDispatch } from "redux-store/hooks";
import { viewAlert } from "redux-store/notifications";
import HandleResponsiveView, { screenSizes } from "utils/ResponsiveView";
import Loader from "components/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const Address = (params: {data: IAddress, onRefresh: () => void}) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const dispatch = useAppDispatch();
	const [viewEditAddressModal, setViewEditAddressModal] = useState(false);

	const handleDeleteItem = async () => {
    await deleteAddress(params.data.id as number)
    .then((resp) => {
      params.onRefresh();
      dispatch(viewAlert({text: "Адрес успешно удален", type: "success"}));
    })
    .catch((error) => {
      dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
      navigate("/authorization?next=" + location.pathname + location.search);
    });
	}

	const handleEditItem = () => {
		setViewEditAddressModal(true);
	}

  const screenSize = HandleResponsiveView();

  switch(screenSize.width) {
    case screenSizes.__480.width:
    case screenSizes.__320.width:
      return (
        <div className={styles.address_block_table_row}>
          <div className={styles.address_mobile_item}>
            <h2>Адрес</h2>
            <p className={styles.address}>{params.data.country + ", " + params.data.region + ", " + params.data.city + ", " + params.data.street + " " + params.data.home}</p>
          </div>

          <div className={styles.address_mobile_item}>
            <h2>Почтовый индекс</h2>
            <p className={styles.address_additional}>{params.data.postcode}</p>
          </div>

          <div className={styles.address_mobile_item}> 
            <h2>По умолчанию</h2>
            <p className={styles.address_additional}>{params.data.isDefault ? "Да" : "Нет" }</p>
          </div>

          <div className={styles.address_mobile_actions}>
            <button className={styles.button_edit} onClick={() => handleEditItem()}>
              <img src={IconEdit} alt="edit"/>
            </button>
            <button className={styles.button_delete} onClick={() => handleDeleteItem()}>
              <img src={IconDelete} alt="delete"/>
            </button>
          </div>
          
          <Modal 
            open={viewEditAddressModal} 
            onClose={() => setViewEditAddressModal(false)} 
            center
            classNames={{modal: "new_address_container"}}
            blockScroll={false}
          >
            <AddressModal closeModal={() => setViewEditAddressModal(false)} data={params.data} onRefresh={params.onRefresh}/>
          </Modal>
        </div>
      );	

    default: 
      return (
        <div className={styles.address_block_table_row}>
          <p className={styles.address}>{params.data.country + ", " + params.data.region + ", " + params.data.city + ", " + params.data.street + " " + params.data.home}</p>
          <p className={styles.address_additional}>{params.data.postcode}</p>
          <p className={styles.address_additional}>{params.data.isDefault ? "Да" : "Нет" }</p>
          <button className={styles.button_edit} onClick={() => handleEditItem()}>
            <img src={IconEdit} alt="edit"/>
          </button>
          <button className={styles.button_delete} onClick={() => handleDeleteItem()}>
            <img src={IconDelete} alt="delete"/>
          </button>
    
          <Modal 
            open={viewEditAddressModal} 
            onClose={() => setViewEditAddressModal(false)} 
            center
            classNames={{modal: "new_address_container"}}
          >
            <AddressModal closeModal={() => setViewEditAddressModal(false)} data={params.data} onRefresh={params.onRefresh}/>
          </Modal>
        </div>
      );	
  } 
}

const UserAddresses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [viewNewAddressModal, setViewNewAddressModal] = useState(false);
  const [userAddresses, setUserAddresses] = useState<IAddress[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getAddresses()
      .then((resp) => setUserAddresses(resp.data as IAddress[]))
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
      setIsLoading(false);
    }) ()
  }, [refresh]);

  const handleRefresh = () => {
    setRefresh(prev => !prev);
  };

  return (
    <div className={styles.container}>
      {
				isLoading ? 
          <Loader />
        : 
        userAddresses?.length === 0 ? 
          <p>У вас нет добавленных адресов</p> 
        :
          <div className={styles.addresses_container}>
            <div className={styles.address_block_header}>
              <p className={styles.address}>Адрес</p>
              <p className={styles.address_additional}>Почтовый индекс</p>
              <p className={styles.address_additional}>По умолчанию</p>
            </div>

            {
              userAddresses.map((address) => 
                <Address 
                  onRefresh={handleRefresh}
                  key={address.id}
                  data={address}
                />
              )
            }
          </div>
			}

			<button onClick={() => setViewNewAddressModal(true)}>Добавить</button>

			<Modal 
        open={viewNewAddressModal} 
        onClose={() => setViewNewAddressModal(false)} 
				center
				classNames={{modal: "new_address_container"}}
        blockScroll={false}
      >
        <AddressModal closeModal={() => setViewNewAddressModal(false)} onRefresh={handleRefresh}/>
      </Modal>
    </div>
  );
}

export default UserAddresses;
