import axios from "axios";
import store from "redux-store/store";
import { SERVER_ADDRESS, SERVER_API_VERSION } from "./config";
import validateTokens from "./auth";

export interface IOrderItem {
  Id: number;
  Size: string;
  Color: string | null;
  Price: number;
  Count: number;
}

export interface IOrder {
  id?: number;
  user_id?: number;
  date?: string;
  isPaid?: boolean;
  payment_id?: string;

  data: IOrderItem[];
  address: string;
  fast_order: boolean;
  status: 0 | 1 | 2 | 3 | 4;
  total_price: number;
}

export interface IPayment {
  id: string;
  confirmation_url: string;
}

export async function createOrder(order: IOrder) {
  try {
    await validateTokens();

    const response = await axios.post(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}orders/`, order,
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response.data as IPayment;
  } catch (error: any) {
    throw error;
  }
}

export async function getOrders() {
  try {
    await validateTokens();

    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}orders/`, 
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` } }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}

export async function getOrder(order_id: string) {
  try {
    await validateTokens();
    
    const response = await axios.get(
    `${SERVER_ADDRESS}${SERVER_API_VERSION}orders/`, 
    { headers: { Authorization: `Bearer ${store.getState().auth.authTokens.access}` }, params: {order_id: order_id} }
    );
    return response;
  } catch (error: any) {
    throw error;
  }
}