import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store';

interface IAdminData {
    current_rate: number;
}

const initialState: IAdminData = {
  current_rate: 0
}

export const adminDataSlice = createSlice({
  name: 'adminData',
  initialState,
  reducers: {
    setAdminData: (state, data) => {
      state.current_rate = data.payload.current_rate.current_rate;
    }
  },
})

export const { setAdminData } = adminDataSlice.actions;
export const selectAdminData = (state: RootState) => state.adminData;
export default adminDataSlice.reducer;