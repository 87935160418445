import Loader from "components/Loader";
import styles from "pages/user/UserOrders.module.scss"
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store/hooks";
import { viewAlert } from "redux-store/notifications";
import { getOrders, IOrder, IOrderItem } from "server-api/orders";
import { IProduct, productGetById } from "server-api/product";

const getStatus = (status: number) => {
  switch(status) {
    case 0:
      return "Создан";
    
    case 1:
      return "Собирается";

    case 2:
      return "Отправлен";

    case 3:
      return "Доставлен";

    case 4:
      return "Отменен";
  }
}

const OrderProduct = ({data}: {data: IOrderItem}) => {
  const dispatch = useAppDispatch();
  const [productData, setProductData] = useState<IProduct>();

  useEffect(() => {
    (async () => {
      await productGetById(String(data.Id))
      .then((resp) => {
        setProductData(resp.data as IProduct);
      })
      .catch((error) => {
        dispatch(viewAlert({text: "Продукт с таким id не найден", type: "error"}));
      });
    }) ()
  }, [data.Id]);

  return (
    <div className={styles.basket_item}>
      <div className={styles.img_container}>
        <img src={productData?.images.Images[0]} alt="product img" />
      </div>
      
      <div className={styles.basket_descriptions}>
        <Link to={"/product?id=" + data.Id}>
          <h1>{productData?.title} x {data.Count}</h1>
        </Link>
        <p>
          Артикул: {productData?.article}<br></br>
          {
            data.Color === "" ? <>Размер: <b>{data.Size}</b> </> : <>Цвет: <b>{data.Color}</b>, размер: <b>{data.Size}</b></>
          }
        </p>

        <h2>{data.Price * data.Count} ₽</h2>
      </div>
    </div>
  );
}

const OrderItem = ({ data }: { data: IOrder }) => {
  const [open, setOpen] = useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <div className={styles.order}>
      <div className={styles.order_top_container}>
        <div className={styles.order_info}>
          <h2>Заказ #{data.id}</h2>
          <p>Дата создания заказа: {data.date}<br></br>Статус: { data.status === 4 ? getStatus(data.status) : data.isPaid === false ? "Неоплачен" : getStatus(data.status) }</p>
          <p>Цена: { data.total_price } ₽</p>
        </div>

        <div className={styles.buttons_container}>
          <button onClick={() => setOpen(prev => !prev)}>Посмотреть товары</button>

          {
            ((data.status === 0) && (data.isPaid === false)) && 
              <button 
                onClick={() => 
                  window.open(`https://yoomoney.ru/checkout/payments/v2/contract?orderId=${data.payment_id}`, "_blank")
                }
                className={styles.paid_button}
              >
                Оплатить
              </button>
          }
          </div>
      </div>

      <div className={styles.order_products_container} ref={contentRef} style={open ? { height: contentRef.current?.scrollHeight + "px" } : { height: "0px" }}>
        <div className={styles.divider}></div>

        {
          data.data.map((item) => <OrderProduct key={`${item.Id}${item.Size}${item.Color}${item.Price}`} data={item} />)
        }
      </div>
    </div>
  );
}

const UserOrders = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await getOrders()
      .then((resp) => {
        setOrders((resp.data).length > 0 ? (resp.data as IOrder[]).sort((a, b) => b.status - a.status) : resp.data);
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });

      setIsLoading(false);
    }) ()
  }, []);

  return (
    <div className={styles.container}>
      {
        isLoading ? <Loader />
        :
          orders.length === 0 ? <p>У вас нет заказов</p>
          :
          <>
            {
              orders.map((order) => <OrderItem key={order.id} data={order}/>)
            }
          </>
      }
    </div>
  );
}

export default UserOrders;