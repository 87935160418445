import axios from "axios";
import store from "redux-store/store";
import { SERVER_ADDRESS, SERVER_API_VERSION } from "./config";
import { setAdminData } from "redux-store/admin-data";

export async function adminDataGet() {
  try {
    const response = await axios.get(`${SERVER_ADDRESS}${SERVER_API_VERSION}admin-data/`);
    store.dispatch(setAdminData({ current_rate: response.data }));
  } catch (error: any) {
    throw error;
  }
}