import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './store';
import { IAlert } from 'components/Alert';

interface NotificationState extends IAlert {
  isShow: boolean;
}
  
const initialState: NotificationState = {
  isShow: false,
  text: "",
  type: "warning"
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    viewAlert: (state, action: {payload: IAlert, type: string}) => {
      state.isShow = true;
      state.text = action.payload.text; 
      state.type = action.payload.type; 
    },
    hideAlert: (state) => {
      state.isShow = false;
    }
  },
})

export const { viewAlert, hideAlert } = notificationSlice.actions
export const selectNotification = (state: RootState) => state.notification.isShow
export default notificationSlice.reducer