import styles from "pages/user/UserPage.module.scss"
import { Link, useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";
import { ReactElement, ReactHTML } from "react";
import UserAddresses from "./UserAddresses";
import UserOrders from "./UserOrders";
import UserFavourite from "./UserFavourite";
import UserBasket from "./UserBasket";
import { useAppDispatch } from "redux-store/hooks";
import { setStateLogout } from "redux-store/user-auth";
import UserCreateOrder from "./UserCreateOrder";

interface UserPageMenu {
  page: "profile" | "addresses" | "orders" | "favourites" | "basket" | "create-order"
};

const MenuButtons : {id: string, link: string, title: string, page: ReactElement<any, any>}[] = [
  {id: "profile", link: "/profile", title: "Профиль", page: <UserProfile />},
  {id: "addresses", link: "/addresses", title: "Мои адреса", page: <UserAddresses />},
  {id: "orders", link: "/orders", title: "Мои заказы", page: <UserOrders />},
  {id: "favourites", link: "/favourites", title: "Избранное", page: <UserFavourite />},
  {id: "basket", link: "/basket", title: "Корзина", page: <UserBasket />}
]

const UserPage = (params: UserPageMenu) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnClickExit = () => {
    dispatch(setStateLogout());
    navigate("/");
  }

  return (
    <section>
      <div className={styles.container}>
        <h1>Личный кабинет</h1>

        <div className={styles.content}>
          <div className={styles.menu_block}>
            {
              MenuButtons.map((button) => { 
                if (button.id !== params.page) {
                  return (
                    <Link to={button.link} key={button.id}>
                      <button className={styles.button_unselected}>{button.title}</button>
                    </Link>
                  );
                } else {
                  return <button key={button.id} className={styles.button_unactive}>{button.title}</button>;
                }
              })
            }

            <button className={styles.button_unselected} onClick={() => handleOnClickExit()}>Выйти</button>
          </div>

          {
            params.page === "create-order" ? 
              <UserCreateOrder /> 
            : 
              MenuButtons.filter((item) => item.id === params.page)[0].page
          }
        </div>
      </div>
    </section>
  );
}

export default UserPage;
