import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import validateTokens from "server-api/auth";

interface IProtectedPage {
  page: JSX.Element;
}

export default function ProtectedPage(params : IProtectedPage) {
  const location = useLocation();
  const [valideTokens, setValideTokens] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await validateTokens()
      .then((resp) => setValideTokens(true))
      .catch((err) => console.log(err));

      setIsLoading(false);
    }) ();
  }, []);

  return(
    <>
    {
      isLoading ? 
        <Loader /> 
      :
        valideTokens ?
          params.page
        :
          <Navigate to={"/authorization?next=" + location.pathname} replace={true} />
    }
    </>
  )
}