import styles from "components/modals/AddressModal.module.scss"
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store/hooks";
import { viewAlert } from "redux-store/notifications";
import { IAddress, createAddress, updateAddress } from "server-api/addresses";
import HandleResponsiveView, { screenSizes } from "utils/ResponsiveView";

const AddressModal = (params: {closeModal: () => void, data?: IAddress, onRefresh: () => void}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const screenSize = HandleResponsiveView();

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElements = e.currentTarget.elements as typeof e.currentTarget.elements & {
      country: HTMLInputElement,
      city: HTMLInputElement,
      region: HTMLInputElement,
      street: HTMLInputElement,
      home: HTMLInputElement,
      postcode: HTMLInputElement,
      default: HTMLInputElement
    };

    if (params.data) {
      await updateAddress({ 
        id: params.data.id,
        country: formElements.country.value, 
        city: formElements.city.value,
        region: formElements.region.value,
        street: formElements.street.value, 
        home: formElements.home.value,
        postcode: Number.parseInt(formElements.postcode.value),
        isDefault: formElements.default.value === "Да" ? true : false
      })
      .then (() => {
        params.onRefresh();
        params.closeModal();
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
    } else {
      await createAddress({ 
        country: formElements.country.value, 
        city: formElements.city.value,
        region: formElements.region.value,
        street: formElements.street.value, 
        home: formElements.home.value,
        postcode: Number.parseInt(formElements.postcode.value),
        isDefault: formElements.default.value === "Да" ? true : false
      })
      .then (() =>{ 
        params.onRefresh();
        params.closeModal();
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });
    }
  }

  return (
    <div className={styles.container}>
      <h1>{ params.data ? "Редактировать адрес" : "Новый адрес" }</h1>

      <form onSubmit={handleOnSubmit} id="address_form">
        <table>
          <tbody>
            {
              screenSize.width <= screenSizes.__480.width ? 
                <>
                  <tr>
                    <td>Страна</td>
                    <td>
                      <input 
                        name='country' 
                        type="text" 
                        placeholder="Россия" 
                        autoComplete="address-level1"
                        required
                        maxLength={50}
                        defaultValue={ params.data ? params.data.country : ""}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Улица</td>
                    <td>
                      <input 
                        name='street' 
                        type="text" 
                        placeholder="Ул. Ленина" 
                        required
                        maxLength={50}
                        defaultValue={ params.data ? params.data.street : ""}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Республика / Область</td>
                    <td>
                      <input 
                        name='region' 
                        type="text" 
                        placeholder="Ленинградская область" 
                        required
                        maxLength={50}
                        defaultValue={ params.data ? params.data.region : ""}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Дом / квартира</td>
                    <td>
                      <input 
                        name='home' 
                        type="text" 
                        placeholder="32-215" 
                        required
                        maxLength={30}
                        defaultValue={ params.data ? params.data.home : ""}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Город</td>
                    <td>
                      <input 
                        name='city'
                        type="text" 
                        placeholder="Санкт-Петербург" 
                        autoComplete="address-level2"
                        required
                        maxLength={50}
                        defaultValue={ params.data ? params.data.city : ""}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Почтовый индекс</td>
                    <td>
                      <input 
                        name='postcode' 
                        type="text" 
                        placeholder="123456" 
                        autoComplete="postal-code"
                        required
                        maxLength={10}
                        defaultValue={ params.data ? params.data.postcode : ""}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>По умолчанию</td>
                      <td>
                        <input 
                          id="yes" 
                          name="default" 
                          type="radio" 
                          value="Да" 
                          required
                          defaultChecked={params.data ? (params.data.isDefault ? true : false) : false} 
                        />

                        <label htmlFor="yes">Да</label>

                        <input 
                          id="not" 
                          name="default" 
                          type="radio" 
                          value="Нет" 
                          required
                          defaultChecked={params.data ? (!params.data.isDefault ? true : false) : false}
                        />

                        <label htmlFor="not">Нет</label>
                      </td>
                  </tr>
                </>
              :
                <>
                  <tr>
                    <td>Страна</td>
                    <td>
                      <input 
                        name='country' 
                        type="text" 
                        placeholder="Россия" 
                        required
                        maxLength={50}
                        defaultValue={ params.data ? params.data.country : ""}
                      />
                    </td>

                    <td>Улица</td>
                    <td>
                      <input 
                        name='street' 
                        type="text" 
                        placeholder="Ул. Ленина" 
                        required
                        maxLength={50}
                        defaultValue={ params.data ? params.data.street : ""}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Республика / Область</td>
                    <td>
                      <input 
                        name='region' 
                        type="text" 
                        placeholder="Ленинградская область" 
                        required
                        maxLength={50}
                        defaultValue={ params.data ? params.data.region : ""}
                      />
                    </td>

                    <td>Дом / квартира</td>
                    <td>
                      <input 
                        name='home' 
                        type="text" 
                        placeholder="32-215" 
                        required
                        maxLength={30}
                        defaultValue={ params.data ? params.data.home : ""}
                      />
                    </td>
                    
                  </tr>

                  <tr>
                    <td>Город</td>
                    <td>
                      <input 
                        name='city'
                        type="text" 
                        placeholder="Санкт-Петербург" 
                        autoComplete="address-level2"
                        required
                        maxLength={50}
                        defaultValue={ params.data ? params.data.city : ""}
                      />
                    </td>

                    <td>Почтовый индекс</td>
                    <td>
                      <input 
                        name='postcode' 
                        type="text" 
                        placeholder="123456" 
                        autoComplete="postal-code"
                        required
                        maxLength={10}
                        defaultValue={ params.data ? params.data.postcode : ""}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>По умолчанию</td>
                      <td>
                        <input 
                          id="yes" 
                          name="default" 
                          type="radio" 
                          value="Да" 
                          required
                          defaultChecked={params.data ? (params.data.isDefault ? true : false) : false} 
                        />

                        <label htmlFor="yes">Да</label>

                        <input 
                          id="not" 
                          name="default" 
                          type="radio" 
                          value="Нет" 
                          required
                          defaultChecked={params.data ? (!params.data.isDefault ? true : false) : false}
                        />

                        <label htmlFor="not">Нет</label>
                      </td>
                  </tr>
                </>
            }
          </tbody>
        </table>

        <button 
          type="submit" 
          form="address_form"
        >
          Сохранить
        </button>
      </form>
    </div>
  );
}

export default AddressModal;
