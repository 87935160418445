import { configureStore } from '@reduxjs/toolkit'
import authReducer from './user-auth'
import notificationReducer from './notifications'
import adminDataReducer from './admin-data'

const store = configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer,
    adminData: adminDataReducer
  },
})

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;