import styles from "pages/user/UserCreateOrder.module.scss"
import "components/modals/ModalsGlobalStyles.css"
import React, { useEffect, useLayoutEffect, useState } from "react";
import { IUserProfile, userGetProfile } from "server-api/users";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { viewAlert } from "redux-store/notifications";
import { basketGet, IBasket } from "server-api/basket";
import { IProduct, productGetById } from "server-api/product";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IAddress, getAddresses } from "server-api/addresses";
import { createOrder, IOrderItem } from "server-api/orders";
import HandleResponsiveView, { screenSizes } from "utils/ResponsiveView";
import Loader from "components/Loader";

const BasketItem = ({data, currentRate}: {data: IBasket, currentRate: number}) => {
  const [productData, setProductData] = useState<IProduct>();

  useEffect(() => {
    (async () => {
      await productGetById(String(data.product_id))
      .then((resp) => {
        setProductData(resp.data as IProduct);
      })
      .catch((error) => {});
    }) ()
  }, [data.product_id]);

  return (
    <div className={styles.basket_item}>
      <img src={productData?.images.Images[0]} alt="product image"></img>
      <p>{data.count} x {data.price * currentRate} ₽</p>
    </div>
  );
}

const UserCreateOrder = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userMailSelector = useAppSelector((state) => state.auth.userMail);
  const currentRate = useAppSelector((state) => state.adminData.current_rate);
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState<IBasket[]>();
  const [allPrice, setAllPrice] = useState<number>(0);
  const [userAddresses, setUserAddresses] = useState<IAddress[]>([]);
  const [isLoading, setIsLoading] = useState(0);
  const [userProfile, setUserProfile] = useState<IUserProfile>({
    first_name: "",
    last_name: "",
    middle_name: "",
    phone: "",
    gender: false
  });

  const deliveryRef = React.createRef<HTMLSelectElement>();
  const queryFastDelivery = searchParams.get("fast");
  const screenSize = HandleResponsiveView();

  useLayoutEffect(() => {
    (async () => {
      await getAddresses()
      .then((resp) => {
        if ((resp.data as IAddress[]).length !== 0) {
          setUserAddresses(resp.data as IAddress[]);
        } else {
          dispatch(viewAlert({text: "Неоходимо добавить адрес для доставки", type: "error"}));
          navigate("/addresses");
        }
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });

      setIsLoading(prev => prev + 1);
    }) ();
  }, []);

  useLayoutEffect(() => {
    (async () => {
      await userGetProfile()
      .then((response) => {
        const udata = response.data as IUserProfile;

        if ((udata.first_name === "") || (udata.last_name === "") || (udata.middle_name === "") || (udata.phone === "")) {
          dispatch(viewAlert({text: "Неоходимо заполнить ваши личные данные", type: "error"}));
          navigate("/profile");
        } else {
          setUserProfile(response.data as IUserProfile);
        }
        
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });

      setIsLoading(prev => prev + 1);
    })();
  }, []);

  useLayoutEffect(() => {
    (async () => {
      await basketGet()
      .then((resp) => {
        setItems(resp.data);
        setAllPrice((resp.data as IBasket[]).reduce((total, item) => total += item.price * item.count, 0));
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });

      setIsLoading(prev => prev + 1);
    }) ();
  }, []);

  const handleOnCreateOrder = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const order_items: IOrderItem[] = (items as IBasket[]).map((item: IBasket) => (
      {
        Id: item.product_id, 
        Size: item.size,
        Color: item.color,
        Price: item.price * currentRate,
        Count: item.count
      } as IOrderItem
    ));

    await createOrder({ 
      address: deliveryRef.current?.value as string,
      fast_order: queryFastDelivery === "true" ? true : false,
      status: 0,
      total_price: allPrice * currentRate + (queryFastDelivery === "true" ? 2500 : 0),
      data: order_items
    })
    .then ((resp) => {
      window.open(resp.confirmation_url, "_blank");
      navigate("/orders");
    })
    .catch((error) => {
      dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
      navigate("/authorization?next=" + location.pathname + location.search);
    });
  }

  return (
    <div className={styles.container}>
      {
        (isLoading < 3) ? <Loader />
        :
          <>
            <div className={styles.hor_container}>
              <div className={styles.user_data}>

                <div className={styles.item}>
                  <h2>ФИО получателя</h2>
                  <p>{userProfile.last_name + " " + userProfile.first_name + " " + userProfile.middle_name} </p>
                </div>

                <div className={styles.item}>
                  <h2>Контактные данные</h2>
                  <p>Телефон: {userProfile.phone}<br></br>Email: {userMailSelector as string}</p>
                </div>

                <div className={styles.divider}></div>

                <div className={styles.item}>
                  <h2>Доставка</h2>
                  <p>{ queryFastDelivery === "true" ? "Быстрая" : "Обычная" }</p>
                </div>

                <div className={styles.item}>
                  <h2>Адрес доставки</h2>
                  <select aria-label="Адрес" ref={deliveryRef}>
                    {
                      userAddresses.map((item) => 
                        <option 
                          value={ `${item.postcode}, ${item.country}, ${item.region}, ${item.city}, ${item.street}, ${item.home}` } 
                          defaultChecked={item.isDefault ? true : false}
                          key={item.id}
                        >
                          {`${item.postcode}, ${item.country}, ${item.region}, ${item.city}, ${item.street}, ${item.home}`}
                        </option>
                      )
                    }
                    
                  </select>
                </div>

                <div className={styles.divider}></div>

                <div className={styles.item}>
                  {
                    screenSize.width <= screenSizes.__768.width && 
                    <div className={styles.basket_items}> 
                    {
                      items?.map((item) => <BasketItem key={item.id} data={item} currentRate={currentRate}/>)
                    }
                  </div>
                  }
                  <h2>Итого: <b>{allPrice * currentRate + (queryFastDelivery === "true" ? 2500 : 0)} ₽</b></h2>
                  <button onClick={(e) =>  handleOnCreateOrder(e) }>Оплатить заказ</button>
                </div>
              </div>

              <div className={styles.basket_items}> 
                {
                  screenSize.width > screenSizes.__768.width && 
                  items?.map((item) => <BasketItem key={item.id} data={item} currentRate={currentRate}/>)
                }
              </div>
            </div>
          </>
      }
    </div>
  );
}

export default UserCreateOrder;