import styles from 'components/modals/CategoriesModal.module.scss'
import { IClothes } from 'components/Header'
import { Link } from 'react-router-dom'

interface ICategories {
  data: IClothes[];
  closeModal: () => void;
  itemsPerRow: number;
}

export default function CategoriesModal({data, closeModal, itemsPerRow}: ICategories) {
  const containerStyle = { "--categories-items-counts": itemsPerRow } as React.CSSProperties;

  return (
    <div className={styles.container} style={containerStyle}>
      {
        data.map((item) => 
          <Link key={item.link} to={item.link} onClick={() => closeModal()}>
            <p>{item.isBold ? <b>{item.name}</b> : item.name}</p>
          </Link>
        )
      }
    </div>
  )
}