import styles from 'components/Alert.module.scss'
import IconError from "icons/icon-error.svg"
import IconWarning from "icons/icon-warning.svg"
import IconSuccess from "icons/icon-success.svg"

export interface IAlert {
	text: string;
	type: "error" | "warning" | "success";
}

export default function Alert(params: IAlert) {
	return (
		<div className={params.type === "warning" ? styles.warning : params.type === "success" ? styles.success : styles.error}>
			<img src={params.type === "warning" ? IconWarning : params.type === "success" ? IconSuccess : IconError} alt='icon' />
			<p>{params.text}</p>
		</div>
	);
}