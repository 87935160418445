import styles from 'pages/faq/HowToOrderPage.module.scss'
import RegStep1 from "images/reg/regStep1.png"
import RegStep2 from "images/reg/regStep2.png"
import RegStep3 from "images/reg/regStep3.png"
import RegStep4 from "images/reg/regStep4.png"
import RegStep5 from "images/reg/regStep5.png"

export default function HowToRegPage() {
  return (
    <section>
      <div className={styles.container}>
        <h1>Как зарегистрироваться?</h1>
        <p>1. Перейдите в свой профиль</p>
        <img src={RegStep1} alt='guide'></img>
        <p>2. Если вы на сайте впервые, то пройдите быструю регистрацию и приступайте к покупкам</p>
        <img className={styles.img_mini} src={RegStep2} alt='guide'></img>
        <p>3. Заполните представленные поля</p>
        <img className={styles.img_mini} src={RegStep3} alt='guide'></img>
        <p>4. Получите письмо на указанную почту и подтвердите свой аккаунт</p>
        <img src={RegStep4} alt='guide'></img>
        <p>5. После этого вы должны быть автоматически авторизованы. Если вы вышли
            из аккаунта и хотите войти вновь, то повторите шаг 1, после чего заполните появившиеся
            перед вами поля
        </p>
        <img className={styles.img_mini} src={RegStep5} alt='guide'></img>
      </div>
    </section>
  )
}