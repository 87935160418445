import styles from "pages/user/UserProfile.module.scss"
import "components/modals/ModalsGlobalStyles.css"
import IconSave from 'icons/icon-save.svg'
import Modal from "react-responsive-modal";
import ChangePasswordModal from "components/modals/ChangePasswordModal";
import React, { useEffect, useState } from "react";
import { IUserParam, IUserProfile, userGetProfile, userUpdateProfile } from "server-api/users";
import { useAppDispatch, useAppSelector } from "redux-store/hooks";
import { viewAlert } from "redux-store/notifications";
import HandleResponsiveView, { screenSizes } from "utils/ResponsiveView";
import Loader from "components/Loader";
import { useLocation, useNavigate } from "react-router-dom";

enum UserDataValues {
  FirstName = "first_name",
  LastName = "last_name",
  MiddleName = "middle_name",
  Phone = "phone",
  Gender = "gender"
}

const ChangePassword = ({viewChangePassword, setViewChangePassword}: {viewChangePassword: boolean, setViewChangePassword: (state: boolean) => void}) => {
  return (
    <Modal 
      open={viewChangePassword} 
      onClose={() => setViewChangePassword(false)} 
      center
      classNames={{modal: "change_password_container"}}
    >
      <ChangePasswordModal onClose={() => setViewChangePassword(false)}/>
    </Modal>
  );
}

const UserProfile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userMailSelector = useAppSelector((state) => state.auth.userMail);
  const [viewChangePassword, setViewChangePassword] = useState(false);
  const [userProfile, setUserProfile] = useState<IUserProfile>({
    first_name: "",
    last_name: "",
    middle_name: "",
    phone: "",
    gender: false
  });

  const [saveButtonsState, setSaveButtonsState] = useState<UserDataValues[]>([]);
  const [genderState, setGenderState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const formRef = React.useRef<HTMLFormElement>(null);

  useEffect(() => {
    (async () => {
      await userGetProfile()
      .then((response) => {
        setUserProfile(response.data as IUserProfile);
        setGenderState((response.data as IUserProfile).gender);
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      });

      setIsLoading(false);
    })();
  }, []);

  
  const handleOnGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch((e.target as HTMLInputElement).value) {
      case 'М':
        if (userProfile.gender === false) {
          if (!saveButtonsState.includes(UserDataValues.Gender))
            setSaveButtonsState(prevState => ([...prevState, UserDataValues.Gender]));
        } else {
          if (saveButtonsState.includes(UserDataValues.Gender))
            setSaveButtonsState(prevState => (prevState.filter((item) => item !== UserDataValues.Gender)))
        }

        if (genderState === false)
          setGenderState(true);
        break;
      
      case 'Ж':
        if (userProfile.gender === true) {
          if (!saveButtonsState.includes(UserDataValues.Gender)) 
            setSaveButtonsState(prevState => ([...prevState, UserDataValues.Gender]));
        } else {
          if (saveButtonsState.includes(UserDataValues.Gender))
            setSaveButtonsState(prevState => (prevState.filter((item) => item !== UserDataValues.Gender)))
        }

        if (genderState === true) 
          setGenderState(false);
        break;
    }
  }

  const componentElements = {
    userNameInput: 
      <input 
        name={UserDataValues.FirstName}
        type="text" 
        placeholder="Ваше имя" 
        onKeyUp={(e) => handleOnKeyUp(e, UserDataValues.FirstName)}
        defaultValue={userProfile.first_name}
      />,

    phoneInput:
      <input 
        name={UserDataValues.Phone} 
        type="tel" 
        placeholder="+7..." 
        onKeyUp={(e) => handleOnKeyUp(e, UserDataValues.Phone)}
        defaultValue={userProfile.phone}
      />,
    
    lastNameInput:
      <input 
        name={UserDataValues.LastName}
        type="text" 
        placeholder="Ваша фамилия" 
        onKeyUp={(e) => handleOnKeyUp(e, UserDataValues.LastName)}
        defaultValue={userProfile.last_name}
      />,

    mailInput:
      <input 
        name='email' 
        type="email" 
        placeholder={userMailSelector as string} 
        disabled={true} 
      />,
    
    middleNameInput:
      <input 
        name={UserDataValues.MiddleName}
        type="text"
        placeholder="Ваше отчество" 
        onKeyUp={(e) => handleOnKeyUp(e, UserDataValues.MiddleName)}
        defaultValue={userProfile.middle_name}
      />,

    genderInput:
      <>
        <input 
          id="male" 
          name="gender" 
          type="radio" 
          value="М" 
          checked={ genderState === true }
          onChange={handleOnGenderChange}
        />
        <label htmlFor="male">М</label>
  
        <input 
          id="female" 
          name="gender" 
          type="radio" 
          value="Ж" 
          onChange={handleOnGenderChange}
          checked={ genderState === false }
        />
        <label htmlFor="female">Ж</label>
      </>,

    userNameSaveButton:
      <button 
        className={!saveButtonsState.includes(UserDataValues.FirstName) ? styles.button_save_unactive : styles.button_save} 
        onClick={(e) => { e.preventDefault(); handleOnSave(UserDataValues.FirstName) }}
      >
        <img src={IconSave} alt="save" />
      </button>,
    
    phoneSaveButton:
      <button 
        className={!saveButtonsState.includes(UserDataValues.Phone) ? styles.button_save_unactive : styles.button_save}  
        onClick={(e) => { e.preventDefault(); handleOnSave(UserDataValues.Phone) }}
      >
        <img src={IconSave} alt="save" />
      </button>,

    lastNameSaveButton:
      <button 
        className={!saveButtonsState.includes(UserDataValues.LastName) ? styles.button_save_unactive : styles.button_save}   
        onClick={(e) => { e.preventDefault(); handleOnSave(UserDataValues.LastName) }}
      >
        <img src={IconSave} alt="save" />
      </button>,
    
    middleNameSaveButton:
      <button 
        className={!saveButtonsState.includes(UserDataValues.MiddleName) ? styles.button_save_unactive : styles.button_save}  
        onClick={(e) => { e.preventDefault(); handleOnSave(UserDataValues.MiddleName) }}
      >
        <img src={IconSave} alt="save" />
      </button>,
    
    genderSaveButton:
      <button 
        className={!saveButtonsState.includes(UserDataValues.Gender) ? styles.button_save_unactive : styles.button_save}  
        onClick={(e) => { e.preventDefault(); handleOnSave(UserDataValues.Gender) }}
      >
        <img src={IconSave} alt="save" />
      </button>,
    
    changePswdButton:
      <button onClick={(e) =>  { e.preventDefault(); setViewChangePassword(true); } }>Сменить пароль</button>
  }

  const handleOnSave = async (param: UserDataValues) => {
    let data: IUserParam = {};
    
    if (formRef.current) {
      const formElements = formRef.current.elements as typeof formRef.current.elements & {
        first_name: HTMLInputElement,
        last_name: HTMLInputElement,
        middle_name: HTMLInputElement,
        phone: HTMLInputElement
      };

      switch (param) {
        case UserDataValues.FirstName: 
          data[param] = formElements.first_name.value;
          break;
  
        case UserDataValues.LastName: 
          data[param] = formElements.last_name.value;
          break;
  
        case UserDataValues.MiddleName: 
          data[param] = formElements.middle_name.value;
          break;
  
        case UserDataValues.Phone: 
          data[param] = formElements.phone.value;
          break;
  
        case UserDataValues.Gender: 
          data[param] = !userProfile.gender;
          break;
      }
  
      await userUpdateProfile(data)
      .then((resp) => {
        dispatch(viewAlert({text: "Ваши данные успешно обновлены", type: "success"}));
        setSaveButtonsState(prevState => (prevState.filter((item) => item !== param)));
        setUserProfile(prev => ({...prev, ...data}))
      })
      .catch((error) => {
        dispatch(viewAlert({ text: "Требуется авторизация", type: "warning" }));
        navigate("/authorization?next=" + location.pathname + location.search);
      })
    } else {
      dispatch(viewAlert({text: "Ошибка: Ref is null", type: "error"}))
    }
  }

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, name: UserDataValues) => {
    if (![userProfile[name], ""].includes((e.target as HTMLInputElement).value)) {
      if (!saveButtonsState.includes(name))
        setSaveButtonsState(prevState => ([...prevState, name]))
    } else if (saveButtonsState.includes(name)) {
      setSaveButtonsState(prevState => (prevState.filter((item) => item !== name)))
    }
  }

  const screenSize = HandleResponsiveView();

  switch(screenSize.width) {
    case screenSizes.__1500.width:
    case screenSizes.__1200.width: {
      return (
        <div className={styles.container}>
          {
            isLoading ? <Loader />
            :
              <>
                <form ref={formRef}>
                  <table>
                    <tbody>
                      <tr>
                        <td className={styles.input_text}>Имя</td>
                        <td>{componentElements.userNameInput}</td>
                        <td>{componentElements.userNameSaveButton}</td>
          
                        <td className={styles.input_text}>Номер телефона</td>
                        <td>{componentElements.phoneInput}</td>
                        <td>{componentElements.phoneSaveButton}</td>
                      </tr>
          
                      <tr>
                        <td className={styles.input_text}>Фамилия</td>
                        <td>{componentElements.lastNameInput}</td>
                        <td>{componentElements.lastNameSaveButton}</td>
          
                        <td className={styles.input_text}>Email</td>
                        <td>{componentElements.mailInput}</td>
                      </tr>
          
                      <tr>
                        <td className={styles.input_text}>Отчество</td>
                        <td>{componentElements.middleNameInput}</td>
                        <td>{componentElements.middleNameSaveButton}</td>
          
                        <td className={styles.input_text}>Пол</td>
                        <td>{componentElements.genderInput}</td>
                        <td>{componentElements.genderSaveButton}</td>
                      </tr>
          
                      <tr>
                        <td></td>
                        <td>{componentElements.changePswdButton}</td>
                      </tr>
                    </tbody>
                  </table>
                </form>
          
                <ChangePassword setViewChangePassword={setViewChangePassword} viewChangePassword={viewChangePassword}/>
              </>
          }
        </div>
      );
    }
    
    case screenSizes.__320.width: {
      return (
        <div className={styles.container}>
          {
            isLoading ? <Loader />
            :
              <>
                <form ref={formRef}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className={styles.table_item_small_screen}>
                            <p>Фамилия</p>
                            {componentElements.lastNameInput}
                          </div>
                        </td>
    
                        <td>{componentElements.lastNameSaveButton}</td>
                      </tr>

                      <tr>
                        <td>
                          <div className={styles.table_item_small_screen}>
                            <p>Имя</p>
                            {componentElements.userNameInput}
                          </div>
                        </td>
          
                        <td>{componentElements.userNameSaveButton}</td>
                      </tr>

                      <tr>
                        <td>
                          <div className={styles.table_item_small_screen}>
                            <p>Отчество</p>
                            {componentElements.middleNameInput}
                          </div>
                        </td>
          
                        <td>{componentElements.middleNameSaveButton}</td>
                      </tr>
    
                      <tr>
                        <td>
                          <div className={styles.table_item_small_screen}>
                            <p>Номер телефона</p>
                            {componentElements.phoneInput}
                          </div>
                        </td>
    
                        <td>{componentElements.phoneSaveButton}</td>
                      </tr>
    
                      <tr>
                        <td>
                          <div className={styles.table_item_small_screen}>
                            <p>Email</p>
                            {componentElements.mailInput}
                          </div>
                        </td>
                      </tr>
    
                      <tr>
                        <td>
                          <div className={styles.table_item_small_screen}>
                            <p>Пол</p>
                            <div className={styles.tabel_input_gender_small_screen}>
                              {componentElements.genderInput}
                            </div>
                          </div>
                        </td>
    
                        <td>{componentElements.genderSaveButton}</td>
                      </tr>
          
                      <tr>
                        <td>{componentElements.changePswdButton}</td>
                      </tr>
                    </tbody>
                  </table>
                </form>
          
                <ChangePassword setViewChangePassword={setViewChangePassword} viewChangePassword={viewChangePassword}/>
              </>
          }
        </div>
      );
    }

    default: {
      return (
        <div className={styles.container}>
          {
            isLoading ? <Loader />
            :
            <>
             <form ref={formRef}>
               <table>
                 <tbody>
                    <tr>
                     <td className={styles.input_text}>Фамилия</td>
                     <td>{componentElements.lastNameInput}</td>
                     <td>{componentElements.lastNameSaveButton}</td>
                   </tr>

                   <tr>
                     <td className={styles.input_text}>Имя</td>
                     <td>{componentElements.userNameInput}</td>
                     <td>{componentElements.userNameSaveButton}</td>
                   </tr>

                   <tr>
                     <td className={styles.input_text}>Отчество</td>
                     <td>{componentElements.middleNameInput}</td>
                     <td>{componentElements.middleNameSaveButton}</td>
                   </tr>
 
                   <tr>
                     <td>Номер телефона</td>
                     <td>{componentElements.phoneInput}</td>
                     <td>{componentElements.phoneSaveButton}</td>
                   </tr>
       
                   <tr>
                     <td className={styles.input_text}>Email</td>
                     <td>{componentElements.mailInput}</td>
                   </tr>
       
                   
 
                   <tr>
                     <td className={styles.input_text}>Пол</td>
                     <td>{componentElements.genderInput}</td>
                     <td>{componentElements.genderSaveButton}</td>
                   </tr>
       
                   <tr>
                     <td></td>
                     <td>{componentElements.changePswdButton}</td>
                   </tr>
                 </tbody>
               </table>
             </form>
     
             <ChangePassword setViewChangePassword={setViewChangePassword} viewChangePassword={viewChangePassword}/>
            </>
          }
        </div>
      );
    }
  }
}

export default UserProfile;
