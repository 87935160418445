import Card from 'components/Card';
import Loader from 'components/Loader';
import styles from 'pages/ProductsPage.module.scss';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IProduct, productsFilter, productsSearch } from 'server-api/product';

const ProductsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [products, setProducts] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState<boolean>(false);

  const querySearch = searchParams.get("s");
  const queryMainCategory = searchParams.get("mcat");
  const queryCategory = searchParams.get("scat");
  const querySubCategory = searchParams.get("cat");

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isPostLoading, setPostLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const fetchData = async (page: number) => {
    if ((querySearch !== "") && (querySearch !== null)) {
      await productsSearch(querySearch as string, page)
      .then((resp) => {
        const data = resp.data as IProduct[];

        if (data.length < 20) {
          setHasMore(false);
        } 

        if (page != 1)
          setProducts([...products, ...data]);
        else
          setProducts(data);
      })
      .catch((error) => {});
    } else {
      await productsFilter({
        filterBy: queryMainCategory ? "main_category_link" : queryCategory ? "category_link" : querySubCategory ? "subcategory_link" : "main_category_link",
        value: queryMainCategory ? queryMainCategory : queryCategory ? queryCategory : querySubCategory ? querySubCategory : "clothes",
        count: page
      })
      .then((resp) => {
        const data = resp.data as IProduct[];

        if (data.length < 20) {
          setHasMore(false);
        } 
        
        if (page != 1)
          setProducts([...products, ...data]);
        else
          setProducts(data);
      })
      .catch((error) => {});
    }
  }

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      setPage(prev => prev + 1);
    }
  };

  useEffect(() => {
    if (hasMore && (page > 1)) {
      (async () => {
        setPostLoading(true);
        await fetchData(page);
        setPostLoading(false);
      }) ()
    }
  }, [page]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchData(1);
      setIsLoading(false);
      setPage(1);
      setHasMore(true);
    }) ()
  }, [queryMainCategory, queryCategory, querySubCategory, querySearch, refresh]);
  
  const onChangeSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
    switch (e.target.value) {
      case "none":
        setRefresh(prev => !prev);
        break;

      case "new_up":
        setProducts(prev => [...prev].sort((a, b) => new Date(a.created_at as string).getTime() - new Date(b.created_at as string).getTime()));
        break;

      case "old_up":
        setProducts(prev => [...prev].sort((a, b) => new Date(b.created_at as string).getTime() - new Date(a.created_at as string).getTime()));
        break;

      case "lowcost_up":
        setProducts(prev => [...prev].sort((a, b) => a.price - b.price));
        break;

      case "overprice_up":
        setProducts(prev => [...prev].sort((a, b) => b.price - a.price));
        break;
    }
  }

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        {
          isLoading ? <Loader />
          :
            <>
              <div className={styles.select_wrapper}>
                {
                  querySearch ? <p>Поиск по запросу: "{querySearch}"</p> : <></>
                }

                <select aria-label="Выберите сортировку" onChange={(e) => onChangeSort(e)}>
                  <option value="none" defaultChecked={true}>По умолчанию</option>
                  <option value="new_up">Сначала новые</option>
                  <option value="old_up">Сначала старые</option>
                  <option value="lowcost_up">Сначала дешевые</option>
                  <option value="overprice_up">Сначала дорогие</option>
                </select>
              </div>

              <div className={styles.products_cards}>
              {
                products.length > 0 ?
                  products.map((item) => 
                    <Card 
                      key={item.id}
                      id={item.id}
                      title={item.title}
                      brand={item.brand}
                      main_category_id={item.main_category_id}
                      category_id={item.category_id}
                      sub_category_id={item.sub_category_id}
                      article={item.article}
                      images={item.images}
                      data={item.data}
                      price={item.price}
                    />
                  )
                : <p style={{"textAlign": "center"}}>Товары не найдены</p>
              }
              </div>

              {isPostLoading && <Loader />}
            </>
        }
      </div>
    </section>
  );
}

export default ProductsPage;