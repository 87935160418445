import styles from 'pages/auth/RegisterPage.module.scss';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserAgreementPdf from '../../static/pdf/user_agreement.pdf'
import PolicuProcessingPdn from '../../static/pdf/policy_processing_pdn.pdf'
import PrivacyPolicy from '../../static/pdf/privacy_policy.pdf'
import ShopOffer from '../../static/pdf/shop_offer.pdf'
import { useAppDispatch } from 'redux-store/hooks';
import { viewAlert } from 'redux-store/notifications';
import { userRegister } from 'server-api/users';
import Loader from 'components/Loader';

const RegisterPage = () => {
  const [viewPassword, setViewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCheckboxPasswordChange = () => {
    setViewPassword(prevState => !prevState);
  }

  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formElements = e.currentTarget.elements as typeof e.currentTarget.elements & {
      email: HTMLInputElement,
      password1: HTMLInputElement,
      password2: HTMLInputElement
    };

    if (formElements.password1.value !== formElements.password2.value) {
      dispatch(viewAlert({text: "Пароли должны совпадать", type: "error"}));
      return;
    }

    setIsLoading(true);

    await userRegister({ email: formElements.email.value, password: formElements.password1.value })
    .then (() => navigate("/authorization"))
    .catch((error) =>  setIsLoading(false));
  }

  return (
    <section>
      <div className={styles.container}>
        {
          isLoading ? <Loader />
          :
            <>
              <h1>Регистрация</h1>

              <form onSubmit={handleOnSubmit}>
                <input 
                  name='email' 
                  type="email" 
                  pattern="[^@\s]+@[^@\s]+"
                  required
                  maxLength={30}
                  placeholder="Введите email" 
                />

                <input 
                  name='password1' 
                  minLength={8} 
                  maxLength={30}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                  title="Пароль должен быть 8+ символов и должен содержать минимум одну заглавную букву, одну обычную и одну цифру"
                  required
                  type={ viewPassword ? "text" : "password" }
                  placeholder="Введите пароль" 
                />

                <input 
                  name='password2' 
                  minLength={8} 
                  maxLength={30}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                  title="Пароль должен быть 8+ символов и должен содержать минимум одну заглавную букву, одну обычную и одну цифру"
                  required
                  type={ viewPassword ? "text" : "password" }
                  placeholder="Повторите пароль" 
                />

                <div className={styles.checkbox_block}>
                  <input 
                    name='view-password' 
                    type="checkbox" 
                    placeholder="Показать пароль" 
                    checked={viewPassword} 
                    onChange={handleCheckboxPasswordChange}
                  />
                  <label htmlFor="view-password">Показать пароль</label>
                </div>

                <div className={styles.checkbox_block}>
                  <input 
                    name='agree-terms' 
                    type="checkbox" 
                    placeholder="Я ознакомлен" 
                    required
                  />
                  <label htmlFor="agree-terms">
                    Я ознакомлен с
                    <a href={UserAgreementPdf} target='_blank'> Пользовательским соглашением</a>, 
                    <a href={PolicuProcessingPdn} target='_blank'> Политикой обработки персональных данных</a>, 
                    <a href={PrivacyPolicy} target='_blank'> Политикой конфиденциальности</a>, 
                    <a href={ShopOffer} target='_blank'> Офертой магазина</a>
                  </label>
                </div>

                <button
                  type='submit'
                >
                  Зарегистрироваться
                </button>
              </form>

              <div className={styles.link_block}>
                <p>Уже есть аккаунт?</p>
                <Link to="/authorization">Войти</Link>
              </div>
            </>
        }
      </div>
    </section>
  );
}

export default RegisterPage;
